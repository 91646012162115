import React, { useContext, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { logEventPageVisited } from '../../../events/logEvent'
import NoResultsIlustration from '../../../assets/ilustrations/noResultsIlustration.svg'
import { secondarySecondary } from '../../../style'
import { PrimaryButton } from '../../common/SMButton'
import { AppContext } from '../../../contexts/AppContext'

const style: { [key: string]: React.CSSProperties } = {
  screen: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '40px',
    padding: '20px',
    alignItems: 'center',
    height: '100%',
  },
  image: {
    width: '80%',
    maxWidth: '180px',
  },
  title: {
    fontSize: '1.4rem',
    margin: '20px',
    textAlign: 'center',
  },
}

export default function NoDiscountsFound({
  search,
  handleChangeSearch,
}: {
  search: string
  handleChangeSearch: (newSearch: string) => void
}) {
  const { setNavButtonClicked } = useContext(AppContext)
  const [cookies] = useCookies(['user'])

  useEffect(() => {
    logEventPageVisited(cookies?.user, 'no_discounts_found', search)
  }, [])

  const messageToShow = search ? (
    <div>
      <p>
        No hay resultados que coincidan con
        {' "'}
        {search}
        {'" '}
      </p>
      <p>y los otros filtros seleccionados.</p>
    </div>
  ) : (
    <p>No hay resultados que coincidan con los filtros seleccionados.</p>
  )

  const handleButtonClick = () => {
    if (search) {
      handleChangeSearch('')
    } else {
      //TODO: esto podría ser inteligente y ver que filtro es el que esta bloqueando los resultados
      // para cuando se encuentra se hace setNavButtonClicked(FilterOptionsEnum.X)
      setNavButtonClicked('ALL_FILTERS')
    }
  }

  const buttonMessage = search ? 'Limpiar búsqueda' : 'Revisar filtros'

  return (
    <div style={style.screen}>
      <img src={NoResultsIlustration} style={style.image} alt="No results" />
      <p style={style.title}>No hay resultados</p>
      <div style={{ textAlign: 'center', marginBottom: '30px', color: secondarySecondary }}>
        {messageToShow}
      </div>
      <PrimaryButton onClick={handleButtonClick} buttonname="clean_search_button">
        <p className="button-1">{buttonMessage}</p>
      </PrimaryButton>
    </div>
  )
}
