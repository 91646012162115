import React, { useContext, useEffect, useState } from 'react'
import BookmarkBorderRoundedIcon from '@mui/icons-material/BookmarkBorderRounded'
import { AppContext } from '../../../contexts/AppContext'
import { SMContent, SMVerticalDiv, SMView } from '../../../SMComponents/SMView'
import { SMH2, SMH3, SMP } from '../../../SMComponents/SMText'
import { SMPrimaryButton } from '../../../SMComponents/SMButton'
import { Discount } from '../../../types/discount'
import Favorites from '../../../assets/ilustrations/favorites.svg'
import { DiscountContext } from '../../../contexts/DiscountContext'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import { Button } from '@mui/material'

import {
  SAVE_DISCOUNTS_FIELDS_REQUIRED,
  isAuthAllowedToSaveDiscounts,
} from '../../../consts/loginRestrictions'
import { DiscountScreen } from '../components/DiscountScreen'
import { HeaderFrame } from '../components/HeaderFrame'
import { DiscountsViewsEnum } from '../../../enums/DiscountsViewsEnum'
import { neutralBlack } from '../../../style'

export const SavedDiscounts = ({
  discounts,
  setView,
}: {
  discounts: Discount[]
  setView: (view: DiscountsViewsEnum) => void
}) => {
  const { auth } = useContext(AppContext)
  const { handleOpenLoginDrawer } = useContext(DiscountContext)
  const [savedDiscounts, setSavedDiscounts] = useState<Discount[]>(
    discounts.filter((discount) => auth && auth?.savedDiscounts?.includes(discount.id))
  )
  useEffect(() => {
    setSavedDiscounts(
      discounts.filter((discount) => auth && auth?.savedDiscounts?.includes(discount.id))
    )
  }, [discounts, auth])

  if (!isAuthAllowedToSaveDiscounts(auth)) {
    return (
      <SMView>
        <HeaderFrame removeLeftDiv>
          <Button
            onClick={() => {
              setView(DiscountsViewsEnum.DISCOUNT_DISPLAY)
            }}
          >
            <ArrowBackIosNewRoundedIcon style={{ color: neutralBlack }} />
          </Button>
          <SMH2 center sx={{ width: '100%' }}>
            Completa tu perfil
          </SMH2>
          <div style={{ width: 50 }} />
        </HeaderFrame>
        <SMContent horizontalCenter verticalCenter>
          <SMH3>Para guardar descuentos debes completar tu perfil</SMH3>
          <img src={Favorites} alt="No results" />
          <SMPrimaryButton
            fullWidth
            maxWidth
            onClick={() => {
              handleOpenLoginDrawer({
                source: 'save_discount_view_log_in_button',
                title: 'Completa tu perfil',
                subtitle:
                  'Podrás guardar descuentos y verlos en esta sección. También podrás reservar mesas en restaurantes y acceder a beneficios exclusivos.',
                fields: SAVE_DISCOUNTS_FIELDS_REQUIRED,
                buttonLabel: 'Ver descuentos guardados',
                onSubmit: () => {},
              })
            }}
          >
            Completar perfil
          </SMPrimaryButton>
        </SMContent>
      </SMView>
    )
  }
  return (
    <SMView>
      <HeaderFrame>
        <Button
          onClick={() => {
            setView(DiscountsViewsEnum.DISCOUNT_DISPLAY)
          }}
        >
          <ArrowBackIosNewRoundedIcon style={{ color: neutralBlack }} />
        </Button>
        <SMH2 center sx={{ width: '100%' }}>
          Descuentos guardados
        </SMH2>
        <div style={{ width: 50 }} />
      </HeaderFrame>
      <SMContent>
        {savedDiscounts.length === 0 ? (
          <SMVerticalDiv horizontalCenter verticalCenter flex1>
            <SMH3 center>No tienes descuentos guardados</SMH3>
            <img src={Favorites} alt="No results" />
            <SMP center>
              Utiliza el icono <BookmarkBorderRoundedIcon /> para guardar tus descuentos favoritos.
            </SMP>
          </SMVerticalDiv>
        ) : (
          <SMVerticalDiv horizontalCenter>
            <DiscountScreen
              discountsToShow={savedDiscounts}
              search={''}
              handleChangeSearch={() => {}}
              isSavedDiscountsScreen={true}
              isStar={false}
            />
          </SMVerticalDiv>
        )}
      </SMContent>
    </SMView>
  )
}
