import './intro.css'
import '../../../App.css'
import { useLocation, useNavigate } from 'react-router-dom'
import React, { useContext, useState } from 'react'
import { IIntro, INTRO_LOCATION_PAGE_NAME, SHOW_SEE_DISCOUNTS_BUTTON } from './IIntro'
import { LocationCheckBox } from '../components/LocationCheckBox'
import { AppContext } from '../../../contexts/AppContext'
import { logSMEvent } from '../../../events/logEvent'
import { SMH2 } from '../../../SMComponents/SMText'
import { useFilterOptions } from '../../../hooks/filterOptions'
import { FilterOptionsEnum } from '../../../consts/filterOptions'
import { SMHorizontalDiv } from '../../../SMComponents/SMView'
import { BackButton } from '../components/BackButton'
import { ICheckBox } from '../components/ICheckBox'
import ExpandMoreRounded from '@mui/icons-material/ExpandMoreRounded'

export const SelectLocation = function () {
  const navigate = useNavigate()
  const filterLabel = FilterOptionsEnum.UBICACION
  const { filtersSelected } = useContext(AppContext)
  const filterOptions = useFilterOptions()
  const location = useLocation()
  const showSeeDiscountButton = location.state?.options?.showSeeDiscountButton
  const [showAllLocations, setShowAllLocations] = useState(false)

  return (
    <IIntro
      pageName={INTRO_LOCATION_PAGE_NAME}
      onClickNext={() => {
        logSMEvent('CLICK_NEXT_IN_LOCATION', {
          location: filtersSelected[FilterOptionsEnum.UBICACION]?.join(','),
        })
        return navigate('/descuentos')
      }}
    >
      <SMHorizontalDiv maxSpaceBetween>
        <BackButton
          onClickBack={() => {
            logSMEvent('CLICK_BACK_IN_LOCATION')
            return navigate('/days', SHOW_SEE_DISCOUNTS_BUTTON(showSeeDiscountButton))
          }}
        />
        <SMH2 center>¿En qué región te encuentras?</SMH2>
        <div style={{ width: 30 }} />
      </SMHorizontalDiv>
      <div className="body-days">
        {filterOptions[filterLabel].slice(0, showAllLocations ? 1000 : 7).map((day) => {
          return <LocationCheckBox key={day} location={day} />
        })}
        {!showAllLocations && (
          <SMHorizontalDiv fullWidth sx={{ justifyContent: 'center', marginTop: 10 }}>
            <ICheckBox
              checked={false}
              onClick={() => {
                setShowAllLocations(!showAllLocations)
              }}
              sx={{
                maxWidth: '180px',
                borderRadius: 20,
              }}
            >
              Ver más
              <ExpandMoreRounded />
            </ICheckBox>
          </SMHorizontalDiv>
        )}
      </div>
    </IIntro>
  )
}
