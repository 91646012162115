import React, { useState, useEffect, useCallback } from 'react'
import { GoogleMap, LoadScriptNext, OverlayView } from '@react-google-maps/api'
import style from './Map.module.css'
import { useCookies } from 'react-cookie'

const { locationMarker2, locationMarker, pin } = style

const UsersLocationIcon = ({ usersLocation }) => {
  if (!usersLocation?.latitude || !usersLocation?.longitude) return null
  return (
    <OverlayView
      position={{ lat: usersLocation.latitude, lng: usersLocation.longitude }}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <div className={locationMarker}>
        <div className={locationMarker2} />
        <div className={pin}></div>
      </div>
    </OverlayView>
  )
}

const MAP_DEFAULT_CENTER = {
  lat: -33.4080026,
  lng: -70.6043725,
}
const MAP_DEFAULT_ZOOM = 15

export const Map = ({ mapRef, usersLocation, focusMap, markers }) => {
  const [visibleMarkers, setVisibleMarkers] = useState([])
  const [cookies, setCookie] = useCookies(['savemoney'])
  const zoom = cookies?.location?.zoom || MAP_DEFAULT_ZOOM
  const center = cookies?.location?.center || MAP_DEFAULT_CENTER

  const onMapLoad = useCallback((map) => {
    mapRef.current = map
  }, [])

  const onBoundsChanged = () => {
    const bounds = mapRef.current?.getBounds()
    const newCenter = {
      lat: mapRef.current?.center?.lat() || MAP_DEFAULT_CENTER.lat,
      lng: mapRef.current?.center?.lng() || MAP_DEFAULT_CENTER.lng,
    }
    const newZoom = mapRef.current?.zoom
    setCookie(
      'location',
      { center: newCenter, zoom: newZoom },
      { path: '/', expires: new Date(9999999999999) }
    )
    const newVisibleMarkers = markers.filter(
      (marker) =>
        marker?.props?.location &&
        bounds?.contains({
          lat: marker.props.location.latitude,
          lng: marker.props.location.longitude,
        })
    )
    setVisibleMarkers(newVisibleMarkers)
  }

  useEffect(() => {
    onBoundsChanged()
  }, [markers])

  return (
    <LoadScriptNext googleMapsApiKey="AIzaSyCP6aMycTOEbkyEf5bDDHR5LOmYJDWumZo">
      {/* https://console.cloud.google.com/google/maps-apis/credentials?referrer=search&project=star-15bb5 */}
      <GoogleMap
        mapContainerStyle={{
          width: '100%',
          height: '100%',
        }}
        center={center}
        zoom={zoom}
        onLoad={onMapLoad}
        onDragStart={focusMap}
        onZoomChanged={focusMap}
        options={{
          zoomControl: true,
          streetViewControl: false,
          clickableIcons: false,
          mapTypeControl: false, // remove Map|Satellite|Terrain|Earth
          fullscreenControl: false, // remove full screen button
          styles: [
            {
              featureType: 'poi',
              elementType: 'labels',
              stylers: [{ visibility: 'off' }], // remove poi
            },
          ],
        }}
        onBoundsChanged={onBoundsChanged}
      >
        {visibleMarkers}
        {usersLocation && <UsersLocationIcon usersLocation={usersLocation} />}
      </GoogleMap>
    </LoadScriptNext>
  )
}
