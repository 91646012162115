import React from 'react'
import { primaryPrimary } from '../../../style'
import { logSMEvent } from '../../../events/logEvent'
import { ReactComponent as InstagramIcon } from '../../../assets/instagramIcon.svg'

export const LoadingDiscountsScreen = () => {
  return (
    <div
      style={{
        display: 'flex',
        height: '96vh', // almost all
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <p>Cargando...</p>
      <div style={{ display: 'flex' }}>
        Siguenos
        <a
          href="https://www.instagram.com/savemoney.cl/"
          target="_blank"
          rel="noreferrer"
          style={{ color: primaryPrimary, display: 'flex', marginLeft: 3 }}
          onClick={() => logSMEvent('CLICK_FOLLOW_US', { page: 'table_loading' })}
        >
          @SaveMoney.cl
          <InstagramIcon
            style={{
              margin: '2px 0px 0px 2px',
              fill: primaryPrimary,
              height: '20px',
            }}
          />
        </a>
      </div>
    </div>
  )
}
