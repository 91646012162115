import React from 'react'

import { Checkbox } from '@mui/material'
import { useContext, useEffect, useState } from 'react'

import '../../../App.css'
import { handleFilterSelection } from '../../../helpers/handleFilterSelection'
import { AppContext } from '../../../contexts/AppContext'
import { neutralWhite, secondary40Black } from '../../../style'
import { ICheckBox } from './ICheckBox'
import { SMHorizontalDiv } from '../../../SMComponents/SMView'
import { SMCircle } from '../../../SMComponents/SMOthers'
import { ClubsTypeDB, SubTarjetasType } from '../../../types/clubs'
import { FilterOptionsEnum } from '../../../consts/filterOptions'
import { subTarjetaId } from '../../../helpers/subTarjetaId'

const SubTarjetaCheckBox = ({
  subTarjeta,
  club,
}: {
  subTarjeta: SubTarjetasType
  club: ClubsTypeDB
}) => {
  const appContext = useContext(AppContext)
  const { filtersSelected, setFiltersSelected } = appContext
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    if (club.id) {
      setChecked(
        filtersSelected[FilterOptionsEnum.SUBTARJETA].includes(subTarjetaId(club.id, subTarjeta.id))
      )
    }
  }, [filtersSelected, club])

  let subTarjetaSize = subTarjeta.width && parseInt(subTarjeta.width)
  if (!subTarjetaSize && !subTarjeta.img && club.width) subTarjetaSize = club.width - 6
  if (!subTarjetaSize) subTarjetaSize = 28

  if (subTarjeta.display === false) return null

  return (
    <ICheckBox
      key={subTarjeta.id}
      checked={checked}
      onClick={() => {
        if (club.id)
          handleFilterSelection(
            FilterOptionsEnum.SUBTARJETA,
            subTarjetaId(club.id, subTarjeta.id),
            filtersSelected,
            setFiltersSelected
          )
      }}
      sx={{
        height: 60,
        borderRadius: 20,
      }}
    >
      <SMHorizontalDiv sx={{ flex: 1 }}>
        <SMCircle radius={22}>
          <img
            src={subTarjeta.img || club?.img}
            style={{ maxWidth: subTarjetaSize, maxHeight: subTarjetaSize }}
            alt="."
          />
        </SMCircle>
        <p
          className="subtitle-3"
          style={{ color: checked ? neutralWhite : secondary40Black, textAlign: 'left' }}
        >
          {subTarjeta.label}
        </p>
      </SMHorizontalDiv>
      <Checkbox
        checked={checked}
        sx={{
          '&.Mui-checked': {
            color: neutralWhite,
          },
        }}
      />
    </ICheckBox>
  )
}

export const SubTarjetasCheckBoxs = function ({ club }: { club: ClubsTypeDB }) {
  const appContext = useContext(AppContext)
  const { filtersSelected } = appContext
  if (
    !club.id ||
    !club.subTarjetas ||
    !filtersSelected[FilterOptionsEnum.TARJETAS].includes(club.id)
  ) {
    return null
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '80%',
        marginLeft: 'auto',
      }}
    >
      {club.subTarjetas.map((subTarjeta) => {
        return <SubTarjetaCheckBox key={subTarjeta.id} subTarjeta={subTarjeta} club={club} />
      })}
    </div>
  )
}
