import React, { useState, useEffect, useContext } from 'react'
import { styled } from '@mui/material/styles'
import { grey } from '@mui/material/colors'
import Box from '@mui/material/Box'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import FilterCard from './FilterCard'
import { logSMEvent, stringifyFiltersSelected } from '../../../../events/logEvent'
import { AppContext } from '../../../../contexts/AppContext'

const StyledBox = styled(Box)(() => {
  return {
    backgroundColor: '#fff',
  }
})

const Puller = styled(Box)(() => {
  return {
    width: 30,
    height: 6,
    backgroundColor: grey[300],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
  }
})

const FilterDrawer = function ({ navButtonClicked }: { navButtonClicked: string | false }) {
  const { filtersSelected, setNavButtonClicked } = useContext(AppContext)

  const [open, setOpen] = useState(false)

  const toggleDrawer = (newOpen: boolean) => {
    setOpen(newOpen)
    if (!newOpen) {
      logSMEvent(`CLOSE_DRAWER_${navButtonClicked}`, stringifyFiltersSelected(filtersSelected))
      setNavButtonClicked(false)
    }
  }

  useEffect(() => {
    if (navButtonClicked) toggleDrawer(true)
  }, [navButtonClicked])

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={() => {
        return toggleDrawer(false)
      }}
      onOpen={() => {
        return toggleDrawer(true)
      }}
      swipeAreaWidth={0} // this is the width of the area where the swipe is detected. 0 means the whole screen
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{
        style: {
          height: '70%', // ojo al cambiar que navButtonClicked puede ser false
          overflow: 'visible', // this is needed to make the drawer scrollable
        },
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: -55,
          borderTopLeftRadius: 18,
          borderTopRightRadius: 18,
          right: 0,
          left: 0,
          backgroundColor: '#fff',
        }}
      >
        <Puller />
        <CloseRoundedIcon
          sx={{
            position: 'relative',
            margin: '15px',
          }}
          onClick={() => {
            return toggleDrawer(false)
          }}
        />
      </div>
      <StyledBox
        sx={{
          px: 2,
          pb: 2,
          height: '100%',
          overflow: 'auto',
        }}
      >
        <FilterCard filterLabel={navButtonClicked} toggleDrawer={toggleDrawer} />
      </StyledBox>
    </SwipeableDrawer>
  )
}

export default FilterDrawer
