import './intro.css'
import '../../../App.css'
import { useLocation, useNavigate } from 'react-router-dom'
import { ClubCheckBox } from '../components/ClubCheckBox'
import { IIntro, INTRO_CLUB_PAGE_NAME, SHOW_SEE_DISCOUNTS_BUTTON } from './IIntro'
import { logSMEvent, stringifyTarjetas } from '../../../events/logEvent'
import React, { useContext } from 'react'
import { AppContext } from '../../../contexts/AppContext'
import { Switch, CircularProgress } from '@mui/material'
import { handleSwitchState } from '../../../helpers/handleFilterSelection'
import { SMH2, SMP } from '../../../SMComponents/SMText'
import { SMHorizontalDiv, SMVerticalDiv } from '../../../SMComponents/SMView'
import { useFilterOptions } from '../../../hooks/filterOptions'
import { ClubEnums } from '../../../types/clubs'
import { FilterOptionsEnum } from '../../../consts/filterOptions'
import { BackButton } from '../components/BackButton'

// const clubsCategoryOrder = ['BANK', 'DIGITAL_WALLET', 'TELCOM', 'VECINO', 'NEWS', 'CAJA']

const ClubsBody = () => {
  const { clubs } = useContext(AppContext)
  const filterLabel = FilterOptionsEnum.TARJETAS
  const { filtersSelected, setFiltersSelected } = useContext(AppContext)
  const filterOptions = useFilterOptions()

  if (!clubs) {
    return (
      <SMVerticalDiv horizontalCenter>
        <CircularProgress style={{ margin: 'auto' }} />
        <p>Cargando clubes...</p>
      </SMVerticalDiv>
    )
  }
  return (
    <div className="body-days" style={{}}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          maxWidth: '325px',
          width: '100%',
        }}
      >
        <SMP black>Seleccionar todos</SMP>
        <Switch
          checked={filtersSelected[filterLabel]?.length === filterOptions[filterLabel]?.length}
          onClick={() => {
            handleSwitchState(filterLabel, filtersSelected, setFiltersSelected, filterOptions)
          }}
        />
      </div>
      {(Object.keys(clubs) as ClubEnums[])
        ?.sort((a: ClubEnums, b: ClubEnums) => {
          const orderA = clubs[a].order
          const orderB = clubs[b].order
          if (orderA !== undefined && orderB !== undefined) {
            return orderA - orderB
          }
          if (orderA !== undefined) {
            return -1
          }
          if (orderB !== undefined) {
            return 1
          }
          return 0
        })
        .map((clubId) => {
          return <ClubCheckBox key={clubId} club={clubs[clubId]} />
        })}
    </div>
  )
}

export const SelectClubs = function () {
  const navigate = useNavigate()
  const { filtersSelected } = useContext(AppContext)
  const location = useLocation()
  const showSeeDiscountButton = location.state?.options?.showSeeDiscountButton

  return (
    <IIntro
      pageName={INTRO_CLUB_PAGE_NAME}
      onClickNext={() => {
        logSMEvent('CLICK_NEXT_IN_CLUBS', {
          clubs: stringifyTarjetas(filtersSelected.Tarjetas),
        })
        return navigate('/categories', SHOW_SEE_DISCOUNTS_BUTTON(showSeeDiscountButton))
      }}
    >
      {/* question (title): always in the top center */}
      <SMVerticalDiv>
        <SMVerticalDiv verticalCenter horizontalCenter>
          <SMHorizontalDiv maxSpaceBetween fullWidth>
            <BackButton
              onClickBack={() => {
                if (showSeeDiscountButton) {
                  logSMEvent('SEE_AGAIN_DISCOUNTS')
                  return navigate('/descuentos')
                } else {
                  // go back using navigate
                  logSMEvent('CLICK_BACK_IN_CLUBS')
                  return navigate('/')
                }
              }}
            />
            <SMH2 center>¿Cuál de estos convenios tienes?</SMH2>
            <div style={{ width: 30 }} />
          </SMHorizontalDiv>
        </SMVerticalDiv>
        <SMVerticalDiv>
          <ClubsBody />
        </SMVerticalDiv>
      </SMVerticalDiv>
    </IIntro>
  )
}
