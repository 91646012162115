import React from 'react'
import { createRoot } from 'react-dom/client'

import './index.css'
import AppWrapper from './AppWrapper'
import './App.css'

// import * as serviceWorkerRegistration from './serviceWorkerRegistration'

// Esta funcion permite que cualquier string tenga la posibilidad de llamar .capitalize()
// Por ejemplo "hola mundo".capitalize() => "Hola mundo"
Object.defineProperty(String.prototype, 'capitalize', {
  value() {
    return this.charAt(0).toUpperCase() + this.slice(1)
  },
  enumerable: false,
})

// document.getElementsByName('viewport')[0].content = 'user-scalable=no'
const container = document.getElementById('root')
const root = createRoot(container)
root.render(<AppWrapper />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

// serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log)
