import { useState, useEffect, useContext } from 'react'
import { AppContext, ClubsType } from '../contexts/AppContext'
import { ALL_FILTERS_OPTIONS, FilterOptionsEnum, FilterOptionsType } from '../consts/filterOptions'
import { ClubEnums } from '../types/clubs'
import { subTarjetaId } from '../helpers/subTarjetaId'

const allSubTarjetasIds = (clubs: ClubsType) => {
  const clubsIds = Object.keys(clubs) as ClubEnums[]
  const subTarjetasIds: string[] = []
  clubsIds.forEach((clubId) => {
    const subTarjetas = clubs[clubId].subTarjetas
    if (subTarjetas) {
      subTarjetasIds.push(...subTarjetas.map((subTarjeta) => subTarjetaId(clubId, subTarjeta.id)))
    }
  })
  return subTarjetasIds
}

export const useFilterOptions = () => {
  const { clubs } = useContext(AppContext)

  const [filterOptions, setFilterOptions] = useState(ALL_FILTERS_OPTIONS)

  useEffect(() => {
    setFilterOptions((prevOptions: FilterOptionsType) => ({
      ...prevOptions,
      [FilterOptionsEnum.TARJETAS]: (Object.keys(clubs || {}) as ClubEnums[]).sort((a, b) => {
        if (clubs) {
          const orderA = clubs[a].order
          const orderB = clubs[b].order
          if (orderA !== undefined && orderB !== undefined) {
            return orderA - orderB
          }
          if (orderA !== undefined) {
            return -1
          }
          if (orderB !== undefined) {
            return 1
          }
        }
        return 0
      }),
      [FilterOptionsEnum.SUBTARJETA]: clubs ? allSubTarjetasIds(clubs) : [],
    }))
  }, [clubs])

  return filterOptions
}
