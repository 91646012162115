import React from 'react'
import { useContext, useEffect, useState } from 'react'

import '../../../App.css'
import { Radio } from '@mui/material'
import { handleFilterSelection } from '../../../helpers/handleFilterSelection'
import { AppContext } from '../../../contexts/AppContext'
import { neutralWhite, secondary40Black } from '../../../style'
import { ICheckBox } from './ICheckBox'
import { FilterOptionsEnum, optionLabel } from '../../../consts/filterOptions'
import { RegionesEnum } from '../../../types/regiones'

const filterLabel = FilterOptionsEnum.UBICACION

export const LocationCheckBox = ({ location }: { location: RegionesEnum }) => {
  const appContext = useContext(AppContext)
  const { filtersSelected, setFiltersSelected } = appContext
  const [checked, setChecked] = useState(false)
  useEffect(() => {
    setChecked(filtersSelected[filterLabel].includes(location))
  }, [filtersSelected, location])

  return (
    <ICheckBox
      checked={checked}
      onClick={() => {
        handleFilterSelection(filterLabel, location, filtersSelected, setFiltersSelected)
      }}
      sx={{
        display: 'flex',
        maxWidth: '325px',
        width: '100%',
        flex: 1,
        minHeight: 45,
        maxHeight: 55,
        borderRadius: 20,
      }}
    >
      <div
        style={{
          display: 'flex',
          flex: 1,
          marginLeft: 16,
          alignItems: 'center',
        }}
      >
        <p className="subtitle-3" style={{ color: checked ? neutralWhite : secondary40Black }}>
          {optionLabel[location] || location}
        </p>
      </div>
      <Radio
        style={{
          height: '20px',
          marginRight: 16,
        }}
        checked={checked}
        sx={{
          '&.Mui-checked': {
            color: neutralWhite,
          },
        }}
      />
    </ICheckBox>
  )
}
