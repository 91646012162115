import React, { useContext, useState } from 'react'
import { Avatar, Button, Checkbox, FormControlLabel, Link } from '@mui/material'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import { AppContext } from '../../../contexts/AppContext'
import { SMContent, SMFooter, SMVerticalDiv, SMView } from '../../../SMComponents/SMView'
import { SMH2, SMP } from '../../../SMComponents/SMText'
import { SMMuiTelInput, SMTextField } from '../../../SMComponents/SMInput'
import { SMPrimaryButton } from '../../../SMComponents/SMButton'
import { request } from '../../../helpers/request'
import { useCookies } from 'react-cookie'
import {
  formatRut,
  isValidRut,
  isValidPhoneNumber,
  isValidName,
  isValidUserName,
  isValidEmail,
} from '../../../helpers/validateForms'
import { logSMEvent } from '../../../events/logEvent'
import { HeaderFrame } from '../components/HeaderFrame'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import { neutralBlack } from '../../../style'
import { DiscountsViewsEnum } from '../../../enums/DiscountsViewsEnum'

export const ProfileScreen = ({ setView }: { setView: (view: DiscountsViewsEnum) => void }) => {
  const { auth, setAuth, handleOpenSnackBar } = useContext(AppContext)
  const setCookie = useCookies(['secretToken', 'userId'])[1]
  const [name, setName] = useState<string>(auth && auth.name ? auth.name : '')
  const [username, setUsername] = useState<string>(auth && auth.username ? auth.username : '')
  const [email, setEmail] = useState<string>(auth && auth.email ? auth.email : '')
  const [rut, setRut] = useState<string>(auth && auth.rut ? auth.rut : '')
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState<string>(
    auth && auth.phoneNumber ? auth.phoneNumber : '+56'
  )
  const [isLoading, setIsLoading] = useState(false)

  const handlePhoneNumberChange = (newValue: string) => {
    setPhoneNumber(newValue)
  }

  const handleRutChange = (e: any) => {
    const rut = formatRut(e.target.value)
    setRut(rut)
  }

  const handleCreateUser = async () => {
    setIsLoading(true)
    logSMEvent('CREATE_USER', {
      name,
      phoneNumber,
      username,
      rut,
      email,
    })
    const response = await request('create_user', {
      method: 'POST',
      body: JSON.stringify({
        name,
        phoneNumber,
        username,
        rut,
        email,
      }),
    })
    const { secretToken, id: userId } = response.data
    setCookie('secretToken', secretToken, { path: '/', expires: new Date(9999999999999) })
    setCookie('userId', userId, { path: '/', expires: new Date(9999999999999) })
    setAuth(response.data)
    handleOpenSnackBar({
      message: 'Perfil actualizado',
    })
    setIsLoading(false)
  }

  const isAuthComplete =
    auth && auth.name && auth.phoneNumber && auth.rut && auth.email && auth.username
  return (
    <SMView>
      <HeaderFrame removeLeftDiv>
        <Button
          onClick={() => {
            setView(DiscountsViewsEnum.DISCOUNT_DISPLAY)
          }}
        >
          <ArrowBackIosNewRoundedIcon style={{ color: neutralBlack }} />
        </Button>
        <SMH2 center sx={{ width: '100%' }}>
          {isAuthComplete ? 'Tu Perfil' : 'Completa tu perfil'}
        </SMH2>
        <div style={{ width: 50 }} />
      </HeaderFrame>
      <SMContent>
        <SMVerticalDiv horizontalCenter>
          <Avatar sx={{ width: 64, height: 64 }}>
            <PersonRoundedIcon sx={{ width: 32, height: 32 }} />
          </Avatar>
          <SMP>
            Completalo para guardar tus descuentos favoritos, hacer reservas en restaurantes,
            acceder a beneficios exclusivos y más.
          </SMP>
        </SMVerticalDiv>
        <SMTextField
          validateOnBlur={isValidName}
          textOnBlurError="Nombre y apellido inválido"
          label="Nombre y apellido"
          value={name}
          onChange={(event: any) => setName(event.target.value)}
        />
        <SMTextField
          validateOnBlur={isValidUserName}
          textOnBlurError="Nombre de usuario muy corto"
          label="Usuario"
          value={username}
          onChange={(event: any) => setUsername(event.target.value)}
        />
        <SMTextField
          validateOnBlur={isValidEmail}
          textOnBlurError="Formato incorrecto"
          label="Email"
          value={email}
          onChange={(event: any) => setEmail(event.target.value.trim())}
        />
        <SMMuiTelInput
          validateOnBlur={isValidPhoneNumber}
          textOnBlurError="Número inválido"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
        />
        <SMTextField
          validateOnBlur={isValidRut}
          textOnBlurError="Rut inválido"
          type="numeric"
          label="Rut"
          value={rut}
          onChange={handleRutChange}
          disabled={Boolean(auth && auth?.rut)}
        />
      </SMContent>
      <SMFooter>
        <SMVerticalDiv fullWidth horizontalCenter>
          <FormControlLabel
            control={
              <Checkbox
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
                name="terms"
                color="primary"
              />
            }
            label={
              <SMP>
                Acepto los{' '}
                <Link href="https://www.savemoney.cl/terminos.html" target="_blank" rel="noopener">
                  términos y condiciones
                </Link>{' '}
                sobre privacidad de los datos
              </SMP>
            }
          />
          <SMPrimaryButton
            disabled={
              !isValidPhoneNumber(phoneNumber) ||
              !isValidName() ||
              !isValidRut(rut) ||
              !isValidUserName(username) ||
              !isValidEmail(email) ||
              !termsAccepted
            }
            onClick={handleCreateUser}
            loading={isLoading}
            fullWidth
          >
            {auth ? 'Guardar cambios' : 'Guardar perfil'}
          </SMPrimaryButton>
        </SMVerticalDiv>
      </SMFooter>
    </SMView>
  )
}
