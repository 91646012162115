export const formatRut = (rut: string) => {
  // change the input so it always has a . every 3 number and the last one is separated with a -
  rut = rut.replace(/[^0-9kK]/g, '') // Remove all non-digits number except the character K or k
  // if there is any k change it for a K
  rut = rut.replace(/k/g, 'K')
  // Start with the special case of the first character and a slash
  if (rut.length > 1) {
    // Add the rest of the numbers, with a dot every three digits, and a dash before the last one
    const rest = rut.slice(0, -1)
    // add the dot every three digits
    const formattedRest = rest.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    rut = `${formattedRest}-${rut.slice(-1)}`
  }
  return rut
}

export const isValidRut = (formattedRut: string) => {
  const numberAfterDash: string = formattedRut.slice(-1)
  const rutSinDigito: string = formattedRut.split('-')[0].split('.').join('')
  if (rutSinDigito.length < 7) {
    return false
  }
  const serieNumerica = [2, 3, 4, 5, 6, 7]
  let suma = 0
  let multiplicador = 0
  for (let i = rutSinDigito.length - 1; i >= 0; i--) {
    suma += parseInt(rutSinDigito.charAt(i)) * serieNumerica[multiplicador % 6]
    multiplicador++
  }
  const resto = suma % 11
  const resultado = 11 - resto
  let valorCalculado = ''
  if (resultado === 11) {
    valorCalculado = '0'
  } else if (resultado === 10) {
    valorCalculado = 'K'
  } else {
    valorCalculado = resultado.toString()
  }
  return valorCalculado === numberAfterDash
}

export const isValidPhoneNumber = (phoneNumber: string) => {
  // remove spaces and + sign
  phoneNumber = phoneNumber.replace(/\s/g, '').replace('+', '')
  // check if the length of number is 9
  return phoneNumber.length === 11
}

export const isValidName = () => {
  // must have at least one space, and each part must have at least 4 characters
  // return name.split(' ').every((part) => part.length > 3) && name.split(' ').length > 1
  return true
}

export const isValidUserName = (username: string) => {
  return username.length > 4
}

export const isValidEmail = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}
