import React from 'react'

import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import { Button } from '@mui/material'
import { primaryPrimary } from '../../../style'

export const BackButton = ({ onClickBack }: { onClickBack: () => void }) => {
  const handleClickBack = () => {
    window.scrollTo(0, 0)
    onClickBack()
  }
  return (
    <Button onClick={handleClickBack} style={{ minWidth: 30 }}>
      <ArrowBackIosNewRoundedIcon style={{ color: primaryPrimary }} />
    </Button>
  )
}
