export enum RegionesEnum {
  METROPOLITANA = 'METROPOLITANA',
  ANTOFAGASTA = 'ANTOFAGASTA',
  ARAUCANIA = 'ARAUCANIA',
  ARICA = 'ARICA',
  ATACAMA = 'ATACAMA',
  AYSEN = 'AYSEN',
  BIOBIO = 'BIOBIO',
  COQUIMBO = 'COQUIMBO',
  LAGOS = 'LAGOS',
  LIBERTADOR = 'LIBERTADOR',
  MAGALLANES = 'MAGALLANES',
  MAULE = 'MAULE',
  NUBLE = 'NUBLE',
  RIOS = 'RIOS',
  TARAPACA = 'TARAPACA',
  VALPARAISO = 'VALPARAISO',
  // No sé si esto debiese estar
  TODAS = 'TODAS',
}

export const ALL_REGIONES: RegionesEnum[] = [
  RegionesEnum.METROPOLITANA,
  RegionesEnum.ARICA,
  RegionesEnum.TARAPACA,
  RegionesEnum.ANTOFAGASTA,
  RegionesEnum.ATACAMA,
  RegionesEnum.COQUIMBO,
  RegionesEnum.VALPARAISO,
  RegionesEnum.LIBERTADOR,
  RegionesEnum.MAULE,
  RegionesEnum.NUBLE,
  RegionesEnum.BIOBIO,
  RegionesEnum.ARAUCANIA,
  RegionesEnum.RIOS,
  RegionesEnum.LAGOS,
  RegionesEnum.AYSEN,
  RegionesEnum.MAGALLANES,
]
