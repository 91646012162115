import { ALL_REGIONES } from '../types/regiones'
import { Discount } from '../types/discount'
import dayjs from 'dayjs'
import { ClubEnums } from '../types/clubs'

export const defaultDiscount: Discount = {
  id: '',
  categoria: '',
  club: ClubEnums.BANCODECHILE,
  createdAt: 0,
  deprecated: false,
  ubicacion: '',
  updatedAt: { _nanoseconds: 0, _seconds: 0 },
  XClosestLocation: null,
  tipoConsumo: '',
  XGoogleMapsLink: '',
  titulo: 'titulo',
  cantidadDescuento: '',
  bgimage: 'https://i.pinimg.com/originals/19/db/31/19db31732931019b73bedcf17924f814.jpg',
  logo: '',
  descripcion: '',
  condiciones: '',
  diasNormalizados: 'L,M,W,J,V,S,D',
  fecha_hasta: dayjs().add(1, 'year').format('DD/MM/YYYY'),
  subTarjeta: '',
  direcciones: '',
  local: '',
  mainTagV3: [],
  subTypeV2: [],
  pesosPorLitro: '',
  aiQueryInput: '',
  aiQueryOutputTime: { _nanoseconds: 0, _seconds: 0 },
  gMapsOutputTime: { _nanoseconds: 0, _seconds: 0 },
  topeDescuento: '',
  tags: '',
  url: '',
  regiones: ALL_REGIONES,
  gMapsResults: [],
  gMapsQueries: [],
}
