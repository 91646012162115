import { useQuery } from 'react-query'
import { logSMEvent } from '../events/logEvent'
import { UserAuthType } from '../contexts/AppContext'
import { IS_PROD_ENV } from '../consts/env'

const PROD_URL = 'https://us-central1-star-15bb5.cloudfunctions.net/app/'
const LOCAL_URL = 'http://localhost:5001/star-15bb5/us-central1/app/'

/* CAMBIAR ESTO UNICAMENTE */

let BACKEND_ENV = 'PROD' // PROD, LOCAL

/* FIN CAMBIAR ESTO UNICAMENTE */
if (IS_PROD_ENV) BACKEND_ENV = 'PROD' // no cambiar!

const api = BACKEND_ENV === 'PROD' ? PROD_URL : LOCAL_URL // no cambiar!

const request = async (url: string, data: any = {}, auth: UserAuthType | null | false = null) => {
  data.headers = {
    ...data.headers,
    'Content-Type': 'application/json',
    limit: '100',
    Authorization: auth ? `Bearer ${auth.id} ${auth.secretToken}` : '',
  }

  // data: {
  //   method: 'GET, POST, PUT, DELETE, etc',
  //   mode: 'cors', // no-cors, *cors, same-origin
  //   cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
  //   credentials: 'same-origin', // include, *same-origin, omit
  //   headers: {
  //     'Content-Type': 'application/json',
  //     // 'Content-Type': 'application/x-www-form-urlencoded',
  //   },
  //   redirect: 'follow', // manual, *follow, error
  //   referrerPolicy: 'no-referrer',
  //   body: JSON.stringify(data), // body data type must match "Content-Type" header
  // }

  try {
    const response = await fetch(api + url, data)
    const responseJson = await response.json()
    return responseJson
  } catch (error: any) {
    logSMEvent('ERROR', { id: 'fetch_url: ' + url, message: error.message })
    return false
  }
}

const useRequestWithCache = (key: string, url: string, enabled: boolean = true, data: any = {}) => {
  data.headers = { ...data.headers, 'Content-Type': 'application/json', limit: '100' }
  return useQuery(
    key,
    async () => {
      const res = await fetch(api + url, data)
      const json = await res.json()
      return json
    },
    {
      staleTime: 24 * 60 * 60 * 1000, // 24 hrs time to consider the data fresh
      cacheTime: 24 * 60 * 60 * 1000, // 24 hrs in case its not being used, garbage time
      enabled,
    }
  )
}

export { request, useRequestWithCache }
