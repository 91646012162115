export enum DiscountsViewsEnum {
  DISCOUNT_DISPLAY = 'DISCOUNT_DISPLAY',
  SAVED_LIST = 'SAVED_LIST',
  ACCOUNT = 'ACCOUNT',
}

export enum DiscountsDisplayEnum {
  LIST = 'LIST',
  TABLE = 'TABLE',
  MAP = 'MAP',
  NOT_DECIDED = 'NOT_DECIDED',
}
