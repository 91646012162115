import React from 'react'
import { SwipeableDrawer } from '@mui/material'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { grey } from '@mui/material/colors'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

import { neutralWhite } from '../style'

const StyledBox = styled(Box)(() => {
  return {
    backgroundColor: neutralWhite,
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    right: 0,
    left: 0,
  }
})

const Puller = styled(Box)(() => {
  return {
    width: 30,
    height: 6,
    backgroundColor: grey[300],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
  }
})

export const SMBottomDrawer = (props: any) => {
  const onCloseDrawer = props.onClose
  return (
    <SwipeableDrawer
      anchor="bottom"
      swipeAreaWidth={0}
      disableSwipeToOpen={false} // this is to disable swipe to open
      PaperProps={{
        style: {
          height: '80%',
          overflow: 'visible',
          ...props.style,
          ...props.sx,
        },
      }}
      {...props}
    >
      <StyledBox
        sx={{
          top: -54,
          height: 32,
          borderTopLeftRadius: 18,
          borderTopRightRadius: 18,
        }}
      >
        <Puller />
        <CloseRoundedIcon
          sx={{
            position: 'absolute',
            top: 8,
            right: 10,
          }}
          onClick={onCloseDrawer}
        />
      </StyledBox>
      <StyledBox
        sx={{
          height: 'calc(100% + 24px)',
          top: -24,
          marginBottom: 124,
          flexDirection: 'column',
          overflowY: 'auto', // to allow scrolling
        }}
      >
        {props.children}
      </StyledBox>
    </SwipeableDrawer>
  )
}
