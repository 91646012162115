import React, { useContext, useEffect, useState } from 'react'
import '../../../App.css'
import { handleFilterSelection } from '../../../helpers/handleFilterSelection'
import { AppContext } from '../../../contexts/AppContext'
import { neutralWhite, secondary40Black } from '../../../style'
import { ICheckBox } from './ICheckBox'
import { MAINTAGV3Enum, MAINTAGV3Icons, MAINTAGV3Label } from '../../../types/mainTagV3'
import { FilterOptionsEnum } from '../../../consts/filterOptions'
import { SMHorizontalDiv } from '../../../SMComponents/SMView'

const filterLabel = FilterOptionsEnum.MAINTAGV3

export const MainTagV3CheckBox = function ({ mainTagV3 }: { mainTagV3: MAINTAGV3Enum }) {
  const appContext = useContext(AppContext)
  const { filtersSelected, setFiltersSelected } = appContext
  const [checked, setChecked] = useState(false)
  useEffect(() => {
    setChecked(filtersSelected[filterLabel].includes(mainTagV3))
  }, [filtersSelected, mainTagV3])

  const contentColor = checked ? neutralWhite : secondary40Black

  return (
    <ICheckBox
      checked={checked}
      onClick={() => {
        handleFilterSelection(filterLabel, mainTagV3, filtersSelected, setFiltersSelected)
      }}
      sx={{
        display: 'flex',
        maxWidth: '325px',
        width: '100%',
        flex: 1,
        minHeight: 45,
        maxHeight: 55,
        borderRadius: 20,
      }}
    >
      <SMHorizontalDiv fullWidth sx={{ marginLeft: 16 }}>
        {MAINTAGV3Icons({ fontSize: 20, color: contentColor, marginBottom: 5 })[mainTagV3]}
        <p className="subtitle-3" style={{ color: contentColor }}>
          {MAINTAGV3Label[mainTagV3]}
        </p>
      </SMHorizontalDiv>
    </ICheckBox>
  )
}
