import React from 'react'
import { neutralWhite } from '../style'

export const SMCircle = ({ radius, backgroundColor, sx, style, children, ...rest }: any) => {
  return (
    <div
      {...rest}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: radius * 2,
        width: radius * 2,
        backgroundColor: backgroundColor || neutralWhite,
        borderRadius: 1000,
        ...sx,
        ...style,
      }}
    >
      {children}
    </div>
  )
}
