import { Discount } from '../types/discount'
import { UserLocation } from '../types/main'
import { getClosestLocation } from './location'

export const addDiscountsXClosestLocation = (discount: Discount, usersLocation: UserLocation) => {
  // agrega a discount la property XClosestLocation
  const hasLocation = usersLocation && discount.gMapsResults
  if (!hasLocation) return null

  const locations: {
    lat: number
    lng: number
    placeId: string
  }[] = discount.gMapsResults
    .map((g) => {
      if (g.location?.latitude && g.location?.longitude && g.id) {
        return {
          lat: g.location.latitude,
          lng: g.location.longitude,
          placeId: g.id,
        }
      } else {
        return undefined
      }
    })
    .filter(
      (location): location is { lat: number; lng: number; placeId: string } =>
        location !== undefined
    ) // Filter out undefined entries
  discount.XClosestLocation = getClosestLocation(locations, usersLocation)
}

export const addDiscountsXGoogleMapsLink = (discount: Discount) => {
  // agrega a discount la property XGoogleMapsLink
  if (!discount.XClosestLocation) return null
  let googleMapsHref = 'https://www.google.com/maps/search/?api=1'
  if (discount.XClosestLocation && discount.XClosestLocation.lat && discount.XClosestLocation.lng) {
    googleMapsHref += '&query_place_id='
    googleMapsHref += discount.XClosestLocation.placeId
    googleMapsHref += '&query='
    googleMapsHref += discount.XClosestLocation.lat
    googleMapsHref += ','
    googleMapsHref += discount.XClosestLocation.lng
  } else {
    googleMapsHref += '&query='
    googleMapsHref += discount.local
  }
  discount.XGoogleMapsLink = googleMapsHref
}
