import React from 'react'

import './intro.css'
import '../../../App.css'

import { PrimaryButton } from '../../common/SMButton'
import { useViewportHeight } from '../../../hooks/useViewportHeight'
import { isAppInstalled, isiOS } from '../../../helpers/device'
import { useLocation, useNavigate } from 'react-router-dom'
import { primaryPrimary } from '../../../style'

export const SHOW_SEE_DISCOUNTS_BUTTON = (showSeeDiscountButton: boolean) => {
  return {
    state: {
      options: {
        showSeeDiscountButton: showSeeDiscountButton,
      },
    },
  }
}

export const INTRO_CLUB_PAGE_NAME = 'intro_clubs'
export const INTRO_MAIN_TAG_PAGE_NAME = 'intro_main_tag'
export const INTRO_DAYS_PAGE_NAME = 'intro_days'
export const INTRO_LOCATION_PAGE_NAME = 'intro_location'

const nextPageLabel: any = {
  [INTRO_CLUB_PAGE_NAME]: 'Filtrar categoría',
  [INTRO_MAIN_TAG_PAGE_NAME]: 'Filtrar días',
  [INTRO_DAYS_PAGE_NAME]: 'Filtrar región',
}

const lastIntroPage = INTRO_LOCATION_PAGE_NAME

export const IIntro = function ({
  pageName,
  onClickNext,
  children,
}: {
  pageName: string
  onClickNext: () => void
  children: React.ReactNode
}) {
  const location = useLocation()
  const showSeeDiscountButton = location.state?.options?.showSeeDiscountButton
  const navigate = useNavigate()

  const handleClickNext = () => {
    window.scrollTo(0, 0)
    onClickNext()
  }

  const viewportHeight = useViewportHeight()

  return (
    <div className="full-screen">
      <div className="holder">
        <div className="main-page" style={{ minHeight: viewportHeight - 32 }}>
          {/* body: */}
          {children}
          {/* footer: */}
          <div className="footer" style={isiOS && !isAppInstalled ? { marginBottom: '90px' } : {}}>
            {pageName === lastIntroPage ? (
              <PrimaryButton onClick={handleClickNext} buttonname={`NEXT_INTRO_${pageName}`}>
                <p className="button-1">Ir a descuentos</p>
              </PrimaryButton>
            ) : (
              <>
                <PrimaryButton onClick={handleClickNext} buttonname={`NEXT_INTRO_${pageName}`}>
                  <p className="button-1">
                    {showSeeDiscountButton ? nextPageLabel[pageName] : 'Siguiente'}
                  </p>
                </PrimaryButton>
                {showSeeDiscountButton && (
                  <PrimaryButton
                    onClick={() => {
                      window.scrollTo(0, 0)
                      return navigate('/descuentos')
                    }}
                    buttonname={`SEE_DISCOUNTS_INTRO_${pageName}`}
                    sx={{
                      backgroundColor: 'transparent',
                      color: primaryPrimary,
                      border: `2px solid ${primaryPrimary}`,
                    }}
                  >
                    <p className="button-1">Ir a descuentos</p>
                  </PrimaryButton>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
