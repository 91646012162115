import { logEvent } from 'firebase/analytics'
import { analytics } from '../services/firebase'

import { appVersion } from '../consts/version'
import { FilterOptionsType, shortRegionName } from '../consts/filterOptions'
import { MAINTAGV3Enum, MAINTAGV3LogsAb } from '../types/mainTagV3'
import { IS_DEV_ENV } from '../consts/env'

const tarjetasAbreviation: { [key: string]: string } = {
  'Banco de Chile': 'BC',
  Santander: 'SAN',
  'Banco Security': 'BS',
  Scotiabank: 'SCO',
  'Banco Ripley': 'RIP',
  Movistar: 'MOV',
  'Vecino Las Condes': 'CON',
  'Mi Vitacura': 'VIT',
  'La Reina': 'REI',
  'Club Lectores El Mercurio': 'MER',
  'La Tercera': 'TER',
  RappiCard: 'RAP',
  Tenpo: 'TEN',
  Sbpay: 'SB',
  Chek: 'CHE',
  Entel: 'EN',
  'Banco Estado': 'BE',
  FullCopec: 'COP',
}

export const stringifyTarjetas = (tarjetas: string[]) => {
  if (!tarjetas) return ''
  return tarjetas
    .map((t) => {
      return tarjetasAbreviation[t] || t
    })
    .join(',')
}

export const stringifyMainTagsV3 = (mainTagsV3: MAINTAGV3Enum[]) => {
  if (!mainTagsV3) return ''
  return mainTagsV3
    .map((t) => {
      return MAINTAGV3LogsAb[t] || t
    })
    .join(',')
}

export const stringifyFiltersSelected = (filtersSelected: FilterOptionsType) => {
  // OJO ACA CUANDO AGREGUEMOS LAS SUB TARJETAS!
  // DADO QUE NO HAY QUE LOGEAR SUB-TARJETAS SELECCIONADAS CUYAS TARJETAS NO ESTEN SELECCIONADAS
  if (!filtersSelected) return {}
  return {
    filter_benefit_type: filtersSelected.BenefitType.join(','),
    filter_dias: filtersSelected.Dias.join(','),
    filter_main_tag_v3: stringifyMainTagsV3(filtersSelected.MainTagV3),
    filter_sort: filtersSelected.Sort.join(','),
    filter_tarjetas: stringifyTarjetas(filtersSelected.Tarjetas),
    filter_ubicacion: filtersSelected.Ubicacion.map((u) => {
      return shortRegionName[u] || u
    }).join(','),
    filter_min_discounts_amount: filtersSelected.MinDiscountsAmount?.toString(),
  }
}

const showLogEvents = true

export const logSMEvent = (event_name: string, params = {}) => {
  // console.log only if it is in local
  if (IS_DEV_ENV && showLogEvents) {
    console.log('[SMEvent] ' + event_name, params)
  }
  logEvent(analytics, event_name, {
    ...params,
    app_version: appVersion,
    env: process.env.NODE_ENV,
  })
}

export const logEventPageVisited = (user: string, pageName: string, searchText = '') => {
  logSMEvent('PAGE_VISITED', {
    user,
    app_version: appVersion,
    page_name: pageName,
    search_text: searchText,
  })
}

export const logEventButtonClicked = (user: string, buttonname: any, cookies: any) => {
  const filtersSelected = stringifyFiltersSelected(cookies.filtersSelected)
  logSMEvent('BUTTON_CLICKED', {
    user,
    app_version: appVersion,
    button_name: buttonname,
    ...filtersSelected,
  })
}

// TODO:
// export const logEventSearchText = (user, searchText, amountOfResults) => {
//   analytics.logEvent('w_search_text', {
//     user,
//     app_version: appVersion,
//     search_text: searchText,
//     amount_of_results: amountOfResults,
//   })
// }
