import React from 'react'

import { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import styles from './noConnection.module.css'
import NoInternet from '../../../img/nointernet.png'
import { logEventPageVisited } from '../../../events/logEvent'
import { SMCookies } from '../../../types/main'

const { noConnectionScreen, title } = styles
export const NoConnection = function () {
  const [smCookies] = useCookies<string>(['savemoney'])
  const cookies = smCookies as SMCookies

  useEffect(() => {
    logEventPageVisited(cookies.user, 'no_connection')
  }, [cookies.user])

  return (
    <div className={noConnectionScreen}>
      <p className={title} style={{ textAlign: 'center' }}>
        No hay conexión a Internet
      </p>
      <img
        src={NoInternet}
        style={{
          marginLeft: '40px',
          width: '80%',
          maxWidth: '400px',
        }}
        alt="No results"
      />
    </div>
  )
}
