import React, { useContext } from 'react'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'

import { DiscountContext } from '../../../contexts/DiscountContext'

const HEADER_HEIGHT = 50

export const HeaderFrame = ({
  removeLeftDiv,
  children,
}: {
  removeLeftDiv?: boolean
  children?: any
}) => {
  const { setOpenMenuDrawer } = useContext(DiscountContext)

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        margin: '10px',
        height: HEADER_HEIGHT,
        gap: 8,
      }}
    >
      {removeLeftDiv ? null : <div style={{ width: 30 }} />}
      {children ? children : null}
      <MenuRoundedIcon onClick={() => setOpenMenuDrawer(true)} />
    </div>
  )
}
