import { Button } from '@mui/material'
import React from 'react'
import { useCookies } from 'react-cookie'
import { logEventButtonClicked } from '../../events/logEvent'
import { SMCookies } from '../../types/main'

const ButtonTracked = function ({ ...props }) {
  const oldClick = props.onClick
  const [smCookies] = useCookies<string>(['savemoney'])
  const cookies = smCookies as SMCookies

  props.onClick = () => {
    logEventButtonClicked(cookies.user, props.buttonname, cookies)
    oldClick()
  }

  return <Button style={{ textTransform: 'none' }} {...props} />
}

export default ButtonTracked
