import React, { useContext } from 'react'
import MapIlustration from '../../../assets/ilustrations/mapIlustration.svg'
import { secondarySecondary } from '../../../style'
import { AppContext } from '../../../contexts/AppContext'
import { PrimaryButton } from '../../common/SMButton'
import { MAINTAGV3Enum, MAINTAGV3Label } from '../../../types/mainTagV3'

const style: any = {
  screen: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    margin: '20px',
  },
  image: {
    width: '80%',
    maxWidth: '180px',
  },
  title: {
    fontSize: '1.4rem',
    margin: '20px',
    textAlign: 'center',
  },
}

export default function RestobarMustBeSelectedToSeeMap() {
  const { filtersSelected, setFiltersSelected } = useContext(AppContext)
  const handleButtonClick = () => {
    setFiltersSelected({ ...filtersSelected, MainTagV3: [MAINTAGV3Enum.RESTOBAR_PRESENCIAL] })
  }
  const categorySelectedLabel = MAINTAGV3Label[filtersSelected.MainTagV3[0]]
  return (
    <div style={style.screen}>
      <img src={MapIlustration} style={style.image} alt="No results" />
      {/* <p style={style.title}>No hay resultados</p> */}
      <div style={{ textAlign: 'center', marginBottom: '30px', color: secondarySecondary }}>
        <p>Actualmente estas buscando por: &quot;{categorySelectedLabel}&quot;.</p>
        <p>
          El mapa sólo esta disponible para &quot;
          {MAINTAGV3Label[MAINTAGV3Enum.RESTOBAR_PRESENCIAL]}&quot;.
        </p>
      </div>
      <PrimaryButton onClick={handleButtonClick} buttonname="clean_search_button">
        <p className="button-1">Cambiar a restaurantes</p>
      </PrimaryButton>
    </div>
  )
}
