import React from 'react'
import './intro.css'
import '../../../App.css'
import { useLocation, useNavigate } from 'react-router-dom'
import { IIntro, INTRO_MAIN_TAG_PAGE_NAME, SHOW_SEE_DISCOUNTS_BUTTON } from './IIntro'
import { MainTagV3CheckBox } from '../components/MainTagV3CheckBox'
import { useContext } from 'react'
import { AppContext } from '../../../contexts/AppContext'
import { logSMEvent, stringifyMainTagsV3 } from '../../../events/logEvent'
import { SMHorizontalDiv } from '../../../SMComponents/SMView'
import { SMH2 } from '../../../SMComponents/SMText'
import { useFilterOptions } from '../../../hooks/filterOptions'
import { BackButton } from '../components/BackButton'

export const SelectMainTagV3 = function () {
  const { filtersSelected, setFiltersSelected } = useContext(AppContext)
  const filterOptions = useFilterOptions()
  const navigate = useNavigate()
  const location = useLocation()
  const showSeeDiscountButton = location.state?.options?.showSeeDiscountButton
  return (
    <IIntro
      pageName={INTRO_MAIN_TAG_PAGE_NAME}
      onClickNext={() => {
        logSMEvent('CLICK_NEXT_IN_MAIN_TAGS', {
          mainTagsV3: stringifyMainTagsV3(filtersSelected.MainTagV3),
        })
        setFiltersSelected({ ...filtersSelected, Dias: [] })
        return navigate('/days', SHOW_SEE_DISCOUNTS_BUTTON(showSeeDiscountButton))
      }}
    >
      <SMHorizontalDiv maxSpaceBetween fullWidth>
        <BackButton
          onClickBack={() => {
            logSMEvent('CLICK_BACK_IN_MAIN_TAGS')
            return navigate('/clubs', SHOW_SEE_DISCOUNTS_BUTTON(showSeeDiscountButton))
          }}
        />
        <SMH2 center>¿Qué buscas?</SMH2>
        <div style={{ width: 30 }} />
      </SMHorizontalDiv>
      <div className={'body-days'}>
        {filterOptions.MainTagV3.map((mainTagV3) => {
          return <MainTagV3CheckBox key={mainTagV3} mainTagV3={mainTagV3} />
        })}
      </div>
    </IIntro>
  )
}
