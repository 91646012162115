import { useEffect } from 'react'
import { CookiesProvider } from 'react-cookie'
import { QueryClient, QueryClientProvider } from 'react-query'
import { createTheme, ThemeProvider } from '@mui/material/styles'

import ServiceWorkerWrapper from './views/common/ServiceWorkerWrapper'
import { App } from './App'
import { primaryPrimary } from './style'
import ErrorBoundary from './ErrorBoundary'

const queryClient = new QueryClient()

const customTheme = createTheme({
  typography: {
    fontFamily: '"Nunito"',
  },
  palette: {
    primary: {
      main: primaryPrimary,
    },
  },
})

const AppWrapper = function () {
  // logear bienvenida a devs
  useEffect(() => {
    console.log(
      'Hey dev! Welcome to SaveMoney! :). For any help, feel free to contact me at: https://www.instagram.com/savemoney.cl/'
    )
  }, [])

  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <CookiesProvider>
          <ServiceWorkerWrapper />
          <ThemeProvider theme={customTheme}>
            <App />
          </ThemeProvider>
        </CookiesProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  )
}

export default AppWrapper
