// component simple with a div

import React from 'react'

import { appVersion } from '../../../consts/version'
import { isAndroid, isAppInstalled, isInSafari, isInstagram, isiOS } from '../../../helpers/device'

export const DeviceInfo = function () {
  const appVersionInfo = `appVersion: ${appVersion}`
  const iosInfo = `isiOS: ${isiOS}`
  const androidInfo = `isAndroid: ${isAndroid}`
  const safariInfo = `isInSafari: ${isInSafari}`
  const appInstalledInfo = `isAppInstalled: ${isAppInstalled}`
  const instagramInfo = `isInstagram: ${isInstagram}`
  const navigatorUserAgentInfo = `navigator.userAgent: ${navigator?.userAgent}`
  const windowNavigatorStandaolineInfo = `window.navigator.standalone: ${
    (window?.navigator as any)?.standalone
  }`
  const documentReferrerIncludesInfo = `document.referrer.includes('android-app://'): ${document?.referrer?.includes(
    'android-app://'
  )}`

  return (
    <div>
      <h1>Info del dispositivo</h1>
      <ul>
        <li>{appVersionInfo}</li>
        <li>{iosInfo}</li>
        <li>{androidInfo}</li>
        <li>{safariInfo}</li>
        <li>{appInstalledInfo}</li>
        <li>{instagramInfo}</li>
        <li>----------</li>
        <li>{navigatorUserAgentInfo}</li>
        <li>{windowNavigatorStandaolineInfo}</li>
        <li>{documentReferrerIncludesInfo}</li>
      </ul>
    </div>
  )
}
