import { useState, useEffect } from 'react'
import { logSMEvent } from '../events/logEvent'

const geolocationOptions = {
  enableHighAccuracy: false,
  // timeout: es el tiempo antes de tirar un error
  timeout: 1000 * 60 * 1, // 1 min (1000 ms * 60 sec * 1 minute = 60 000ms)
  // maximumAge: es el tiempo que espera antes de volver a hacer la request
  maximumAge: 1000 * 60 * 5 * 1, // (ms * s * m * h)
}

// TODO: ELIMINAR ESTA FUNCION
const useCurrentLocation = () => {
  // store location in state
  const [location, setLocation] = useState()
  // store error message in state
  const [error, setError] = useState()

  // Success handler for geolocation's `getCurrentPosition` method
  const handleSuccess = (pos) => {
    const { latitude, longitude } = pos.coords

    setLocation({
      latitude,
      longitude,
    })
  }

  // Error handler for geolocation's `getCurrentPosition` method
  const handleError = (error) => {
    setError(error.message)
  }

  useEffect(() => {
    const { geolocation } = navigator

    // If the geolocation is not defined in the used browser we handle it as an error
    if (!geolocation) {
      setError('Geolocation is not supported by the browser.')
      return
    }

    // Call Geolocation API
    geolocation.getCurrentPosition(handleSuccess, handleError, geolocationOptions)
  }, [])

  return { location, error }
}

export const getUsersLocation = (setUsersLocation) => {
  // Success handler for geolocation's `getCurrentPosition` method
  const handleSuccess = (pos) => {
    logSMEvent('USER_LOCATION_SUCCESS', {
      id: 'location_success',
      latitude: pos.coords.latitude,
      longitude: pos.coords.longitude,
    })
    setUsersLocation({
      latitude: pos.coords.latitude,
      longitude: pos.coords.longitude,
    })
  }

  // Error handler for geolocation's `getCurrentPosition` method
  const handleError = (error) => {
    logSMEvent('USER_LOCATION_ERROR', { id: 'location_error', message: error?.message })
    return setUsersLocation({ error: error?.message })
  }

  const { geolocation } = navigator
  // If the geolocation is not defined in the used browser we handle it as an error
  if (!geolocation) {
    logSMEvent('BROWSER_GEOLOCATION_ERROR', {
      id: 'browser_gelocation_error',
      message: '',
    })
    return setUsersLocation({ error: 'Geolocation is not supported by the browser.' })
  }
  // Call Geolocation API
  geolocation.getCurrentPosition(handleSuccess, handleError, geolocationOptions)
}

export default useCurrentLocation
