import React, { useEffect, useState } from 'react'
import { Alert, Button, Snackbar } from '@mui/material'
import * as serviceWorker from '../../serviceWorkerRegistration'

function ServiceWorkerWrapper() {
  const [showNewVersionAvailable, setShowNewVersionAvailable] = useState(false)
  const [waitingWorker, setWaitingWorker] = useState(null)

  const onSWUpdate = (registration) => {
    // console.log('nueva version de la app fue encontrada')
    setShowNewVersionAvailable(true)
    setWaitingWorker(registration.waiting)
  }

  useEffect(() => {
    // console.log('serviceWorker registered onUpdate: onSWUpdate')
    serviceWorker.register({ onUpdate: onSWUpdate })
  }, [])

  const reloadPage = () => {
    // console.log('la app esta siendo actualizada por el usuario')
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' })
    setShowNewVersionAvailable(false)
    window.location.reload(true)
  }

  return (
    <Snackbar
      open={showNewVersionAvailable}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={() => {
        return setShowNewVersionAvailable(false)
      }}
      autoHideDuration={20000}
      onClick={reloadPage}
    >
      <Alert severity="info" sx={{ width: '100%' }}>
        ¡Hay una nueva versión disponible!
        <Button size="small" onClick={reloadPage}>
          ACTUALIZAR
        </Button>
      </Alert>
    </Snackbar>
  )
}

export default ServiceWorkerWrapper
