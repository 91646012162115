import React from 'react'

import { Drawer, Link } from '@mui/material'

import BookmarkRoundedIcon from '@mui/icons-material/BookmarkRounded'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded'
import DiscountRoundedIcon from '@mui/icons-material/DiscountRounded'
import { SMH2, SMP } from '../../../SMComponents/SMText'
import { neutral50White, primaryPrimary } from '../../../style'
import { DiscountsViewsEnum } from '../../../enums/DiscountsViewsEnum'
import { logSMEvent } from '../../../events/logEvent'
import LogoTransparenteAzul from '../../../assets/logo/LogoTransparenteAzul.png'
import Whatsapp from '../../../assets/icons/whatsappGrey.svg'

export const MENU_DRAWER_BODY_WIDTH = 'min(40vw, 250px)'

const DrawerOption = ({
  onClick,
  value,
  children,
}: {
  onClick: (view: DiscountsViewsEnum) => void
  value: DiscountsViewsEnum
  children: any
}) => {
  return (
    <div
      onClick={() => onClick(value)}
      style={{ display: 'flex', alignItems: 'center', gap: 10, color: neutral50White }}
    >
      {children}
    </div>
  )
}

export const MenuDrawer = ({
  setView,
  openMenuDrawer,
  setOpenMenuDrawer,
}: {
  setView: (view: DiscountsViewsEnum) => void
  openMenuDrawer: boolean
  setOpenMenuDrawer: (newState: boolean) => void
}) => {
  const onClick = (newValue: DiscountsViewsEnum) => {
    logSMEvent('CHANGE_DISCOUNT_PAGE', { page: newValue })
    setView(newValue)
  }

  return (
    <Drawer
      anchor="right"
      open={openMenuDrawer}
      sx={{ zIndex: 200 }}
      onClick={() => setOpenMenuDrawer(!openMenuDrawer)}
    >
      <div
        style={{
          width: MENU_DRAWER_BODY_WIDTH,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: 20,
          gap: 40,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 40,
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
            <img src={LogoTransparenteAzul} style={{ width: 30 }} alt="Logo" />
            <SMH2 center sx={{ color: primaryPrimary }}>
              SaveMoney
            </SMH2>
          </div>
          <DrawerOption onClick={onClick} value={DiscountsViewsEnum.DISCOUNT_DISPLAY}>
            <DiscountRoundedIcon />
            <SMP>Descuentos</SMP>
          </DrawerOption>
          <DrawerOption onClick={onClick} value={DiscountsViewsEnum.SAVED_LIST}>
            <BookmarkRoundedIcon />
            <SMP>Guardados</SMP>
          </DrawerOption>
          <DrawerOption onClick={onClick} value={DiscountsViewsEnum.ACCOUNT}>
            <PersonRoundedIcon />
            <SMP>Tu perfil</SMP>
          </DrawerOption>

          <Link
            href="https://wa.me/56974769848"
            target="_blank"
            rel="noopener"
            sx={{ textDecoration: 'none' }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
              <img src={Whatsapp} alt="Whatsapp" width={'18px'} />
              <SMP>Contáctanos</SMP>
              <OpenInNewRoundedIcon
                sx={{ width: 16, marginBottom: '-2px', color: neutral50White, marginLeft: -1 }}
              />
            </div>
          </Link>
        </div>
        <SMP>By wayoalamos</SMP>
      </div>
    </Drawer>
  )
}
