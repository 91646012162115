import React from 'react'
import { Button } from '@mui/material'
import { neutral90White } from '../style'

export const SMChip = ({ children, onClick = () => {}, style, ...rest }: any) => {
  const handleClick = (e: any) => {
    e.preventDefault() // prevent the default action of the browser
    e.stopPropagation() // prevent the click from propagating to the parent
    onClick(e)
  }
  return (
    <Button
      variant="outlined"
      onClick={handleClick}
      style={{
        textTransform: 'none',
        gap: 4,
        border: '1px solid',
        borderColor: neutral90White,
        borderRadius: 10,
        padding: '2px 10px',
        ...style,
      }}
      {...rest}
    >
      {children}
    </Button>
  )
}
