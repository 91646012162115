import { useEffect, useState } from 'react'
import { request } from '../../helpers/request'

import * as React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { visuallyHidden } from '@mui/utils'
import TextField from '@mui/material/TextField'
import { Alert, Button } from '@mui/material'
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded'
import { primary40Black } from '../../style'

function descendingComparator(a, b, orderBy) {
  if (orderBy === 'createdAt') {
    if (a[orderBy]?._seconds < b[orderBy]?._seconds) {
      return -1
    }
    if (a[orderBy]?._seconds > b[orderBy]?._seconds) {
      return 1
    }
    return 0
  }
  if (orderBy === 'club') {
    if (a.discount?.club < b.discount?.club) {
      return -1
    }
    if (a.discount?.club > b.discount?.club) {
      return 1
    }
    return 0
  }

  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

const headCells = [
  {
    id: 'id',
    label: 'Id',
  },
  {
    id: 'club',
    label: 'Club',
  },
  {
    id: 'title',
    label: 'Titulo',
  },
  {
    id: 'sm_link',
    label: 'SM_link',
  },
  {
    id: 'createdAt',
    label: 'Fecha',
  },
  {
    id: 'message',
    label: 'Message',
  },
  {
    id: 'estado',
    label: 'Estado',
  },
  {
    id: 'accion',
    label: 'Acción',
  },
  {
    id: 'eliminar',
    label: 'Eliminar',
  },
]

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
}

const DiscountErrorRow = ({ row, password, deletedList, setDeletedList, setShowAlert }) => {
  return (
    <TableRow
      hover
      onClick={() => {
        console.log(row)
      }}
      role="checkbox"
      tabIndex={-1}
      sx={{ cursor: 'pointer' }}
    >
      <TableCell>
        <a
          href={
            'https://console.firebase.google.com/u/0/project/star-15bb5/firestore/databases/-default-/data/~2FdiscountsError~2F' +
            row.errorId
          }
          target="_blank"
          rel="noreferrer"
        >
          <p>{row.errorId}</p>
        </a>
      </TableCell>
      <TableCell>
        <p>{row.discount?.club || '*-' + row.club}</p>
      </TableCell>
      <TableCell>
        <a href={row.discount?.url} target="_blank" rel="noreferrer">
          {row.discount?.titulo || row.discountTitle}
        </a>
      </TableCell>
      <TableCell>
        <a
          href={'http://savemoney.cl/descuentos?openDiscountId=' + row.discount?.id}
          target="_blank"
          rel="noreferrer"
        >
          <OpenInNewRoundedIcon sx={{ color: primary40Black }} />
        </a>
        <a
          href={
            'https://console.firebase.google.com/u/0/project/star-15bb5/firestore/databases/-default-/data/~2Fdiscounts~2F' +
            row.discount?.id
          }
          target="_blank"
          rel="noreferrer"
        >
          {' '}
          BD
        </a>
        {/*  */}
      </TableCell>
      <TableCell>
        <p>{new Date(row.createdAt?._seconds * 1000).toLocaleDateString()}</p>
      </TableCell>
      <TableCell>
        <p>{row.message}</p>
      </TableCell>
      <TableCell>
        <p>{row.status || 'Reportado'}</p>
      </TableCell>
      <TableCell>
        <Button
          variant="contained"
          disabled={row.status === 'ARREGLADO'}
          onClick={() => {
            const body = JSON.stringify({
              errorId: row.errorId,
              password,
            })
            request('fix_discount_error', {
              method: 'POST',
              body,
            }).then((r) => {
              if (r.status === 'success') {
                setShowAlert('Error fue marcado como arreglado exitosamente')
                // TODO: cambiar estado de la row para que aparezca como arreglada.
              } else {
                alert('Error al eliminar: ' + r.message)
              }
            })
          }}
        >
          Arreglado Manualmente
        </Button>
      </TableCell>
      <TableCell>
        <Button
          variant="outlined"
          color={'warning'}
          onClick={() => {
            const body = JSON.stringify({
              errorId: row.errorId,
              password,
            })
            request('delete_discount_error', {
              method: 'POST',
              body,
            }).then((r) => {
              if (r.status === 'success') {
                setShowAlert('Solicitud fue eliminada exitosamente')
                setDeletedList([...deletedList, row.errorId])
              } else {
                alert('Error al eliminar: ' + r.message)
              }
            })
          }}
        >
          Eliminar
        </Button>
      </TableCell>
    </TableRow>
  )
}

export const DiscountsErrors = () => {
  const [password, setPassword] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('date')
  const [page, setPage] = useState(0)
  const [dense, setDense] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [discountsErrors, setDiscountsErrors] = useState([])

  useEffect(() => {
    request('discounts_errors').then((r) => {
      setDiscountsErrors(r.data)
    })
  }, [])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangeDense = (event) => {
    setDense(event.target.checked)
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - discountsErrors.length) : 0

  const [visibleRows, setVisibleRows] = useState([])
  const [deletedList, setDeletedList] = useState([])

  useEffect(() => {
    setVisibleRows(
      discountsErrors
        .filter((x) => !deletedList.includes(x.errorId))
        .slice()
        .sort(getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    )
  }, [order, orderBy, page, rowsPerPage, deletedList, discountsErrors])

  return (
    <Box sx={{ width: '100%' }}>
      {showAlert && (
        <Alert severity="success" onClose={() => setShowAlert(false)}>
          {showAlert}
        </Alert>
      )}
      <Paper sx={{ width: '100%', mb: 2 }}>
        <Toolbar>
          <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
            Errores totales ({discountsErrors.length})
          </Typography>
        </Toolbar>
        <Typography sx={{ flex: '1 1 100%', ml: 3, mb: 3 }} variant="h7" component="div">
          Errores reportados hoy:{' '}
          {
            discountsErrors.filter(
              (x) =>
                new Date(x.createdAt?._seconds * 1000).toLocaleDateString() ===
                new Date().toLocaleDateString()
            ).length
          }
        </Typography>
        Key:
        <TextField
          type="password"
          sx={{ ml: 3 }}
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          variant="standard"
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
            <TableBody>
              {visibleRows.map((row, index) => (
                <DiscountErrorRow
                  key={index + row.errorId + Math.random()}
                  row={row}
                  password={password}
                  deletedList={deletedList}
                  setDeletedList={setDeletedList}
                  setShowAlert={setShowAlert}
                />
              ))}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={discountsErrors.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Box>
  )
}
