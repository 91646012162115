import pako from 'pako'

export const decompress = function (string: string) {
  // Decode base64 to binary string
  const binaryString = window.atob(string)

  // Convert binary string to character-number array
  const charArray = []
  for (let i = 0; i < binaryString.length; i++) {
    charArray.push(binaryString.charCodeAt(i))
  }

  // Convert character-number array to Uint8Array
  const uint8Array = new Uint8Array(charArray)

  // Decompress the data
  const decompressed = pako.inflate(uint8Array, { to: 'string' })
  return decompressed
}
