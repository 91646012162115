import DiscountsHolder from './DiscountsHolder'
import { SuggestedBottoms } from './SuggestedBottoms'
import React, { useContext } from 'react'
import NoDiscountsFound from './NoDiscountsFound'
import { Discount } from '../../../types/discount'
import { SMH3, SMP } from '../../../SMComponents/SMText'
import { AppContext } from '../../../contexts/AppContext'
import { FilterOptionsEnum } from '../../../consts/filterOptions'
import { ALLMAINTAGSV3, MAINTAGV3Icons, MAINTAGV3Label } from '../../../types/mainTagV3'
import { SMHorizontalDiv } from '../../../SMComponents/SMView'
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { SMIconButton } from '../../../SMComponents/SMButton'
import { logSMEvent } from '../../../events/logEvent'

export const DiscountScreen = function ({
  discountsToShow,
  search,
  handleChangeSearch,
  isSavedDiscountsScreen,
  isStar,
}: {
  discountsToShow: Discount[]
  search: string
  handleChangeSearch: (search: string) => void
  isSavedDiscountsScreen: boolean
  isStar: boolean
}) {
  const { filtersSelected } = useContext(AppContext)
  const [showLessCategories, setShowLessCategories] = React.useState<{ [key in string]: boolean }>(
    {}
  )

  if (discountsToShow.length === 0) {
    return <NoDiscountsFound search={search} handleChangeSearch={handleChangeSearch} />
  }

  // ESTE CODIGO ES IGUAL AL DE DISCOUNTSTABLEVIEW
  const discountsByCategories: { [key in string]: Discount[] } = {}
  let categoriesToShow = isSavedDiscountsScreen
    ? ALLMAINTAGSV3
    : filtersSelected[FilterOptionsEnum.MAINTAGV3]
  if (categoriesToShow.length === 0) {
    categoriesToShow = ALLMAINTAGSV3
  }
  categoriesToShow.forEach((category) => {
    discountsByCategories[category] = []
  })
  discountsToShow.forEach((d) => {
    d?.mainTagV3?.forEach((category) => {
      if (category in discountsByCategories) {
        discountsByCategories[category].push(d)
      }
    })
  })
  // FIN - // ESTE CODIGO ES IGUAL AL DE DISCOUNTSTABLEVIEW

  return (
    <>
      <SMH3 center style={{ margin: '12px', marginTop: '0px' }}>
        Tenemos {discountsToShow.length} descuentos para ti
      </SMH3>
      <SuggestedBottoms isStar={isStar} />
      <div style={{ display: 'flex', flexDirection: 'column', margin: '0px 10px', gap: 20 }}>
        {ALLMAINTAGSV3.map((category) => {
          if (!(category in discountsByCategories)) return null
          return (
            <div key={category} style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
              <SMHorizontalDiv>
                {MAINTAGV3Icons({ width: 20 })[category]}
                <SMH3>{MAINTAGV3Label[category]}</SMH3>
                <SMIconButton
                  onClick={() => {
                    logSMEvent('CLICK_SHOW_MORE_OR_LESS_CATEGORY', {
                      category,
                      isMore: showLessCategories[category],
                    })
                    setShowLessCategories((prev) => ({ ...prev, [category]: !prev[category] }))
                  }}
                >
                  {showLessCategories[category] ? (
                    <ExpandMoreRoundedIcon />
                  ) : (
                    <ExpandLessRoundedIcon />
                  )}
                </SMIconButton>
              </SMHorizontalDiv>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 40 }}>
                {discountsByCategories[category].length === 0 ? (
                  <SMP sx={{ marginLeft: 20 }}> No hay descuentos en esta categoría.</SMP>
                ) : showLessCategories[category] ? null : (
                  <DiscountsHolder
                    discountsToShow={discountsByCategories[category] || []}
                    category={category}
                    isStar={isStar}
                  />
                )}
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}
