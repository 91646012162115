import { LoginDrawerFieldsEnum } from '../enums/LoginDrawerFieldsEnum'
import { UserAuthType } from '../contexts/AppContext'

export const SAVE_DISCOUNTS_FIELDS_REQUIRED = [
  LoginDrawerFieldsEnum.RUT,
  LoginDrawerFieldsEnum.EMAIL,
]
export const isAuthAllowedToSaveDiscounts = (auth: UserAuthType | null | false) => {
  return auth && auth.rut && auth.email
}

export const RESERVE_FIELDS_REQUIRED = [
  LoginDrawerFieldsEnum.RUT,
  LoginDrawerFieldsEnum.PHONENUMBER,
  LoginDrawerFieldsEnum.EMAIL,
]
export const isAuthAllowedToReserved = (auth: UserAuthType | null | false) => {
  return auth && auth.rut && auth.phoneNumber && auth.email
}

export const COMMENT_DISCOUNTS_FIELDS_REQUIRED = [
  LoginDrawerFieldsEnum.USERNAME,
  LoginDrawerFieldsEnum.EMAIL,
  LoginDrawerFieldsEnum.RUT,
]

export const isAuthAllowedToCommentDiscounts = (auth: UserAuthType | null | false) => {
  return auth && auth.username && auth.email && auth.rut
}

export const LIKE_DISCOUNTS_FIELDS_REQUIRED = [
  LoginDrawerFieldsEnum.EMAIL,
  LoginDrawerFieldsEnum.RUT,
]

export const isAuthAllowedToLikeDiscounts = (auth: UserAuthType | null | false) => {
  return auth && auth.email && auth.rut
}
