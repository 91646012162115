import React from 'react'
import { Button } from '@mui/material'

import '../../../App.css'
import { neutral90White, neutralWhite, primary20White, primary40White } from '../../../style'
import { isMobile } from '../../../helpers/device'

export const ICheckBox = function ({
  checked,
  onClick,
  sx,
  children,
}: {
  checked: boolean
  onClick: () => void
  sx?: any
  children: any
}) {
  const backgroundColor = checked ? primary20White : neutralWhite
  let hoverBackgroundColor = backgroundColor
  if (!isMobile) {
    hoverBackgroundColor = checked ? primary40White : neutral90White
  }

  return (
    // this should be a ButtonTracked
    <Button
      onClick={onClick}
      sx={{
        '&:hover': {
          backgroundColor: hoverBackgroundColor,
        },
        ...sx,
        display: 'flex',
        backgroundColor,
        flex: 1,
        marginTop: '8px',
        boxShadow: '2px 2px 4px rgba(70,70,70,0.25)',
        alignItems: 'center',
        textTransform: 'none',
      }}
    >
      {children}
    </Button>
  )
}
