import React, { useContext } from 'react'

import { AppContext } from '../../../contexts/AppContext'
import { FilterOptionsEnum, numberRegion } from '../../../consts/filterOptions'
import { ALL_REGIONES, RegionesEnum } from '../../../types/regiones'
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded'
import { ALLMAINTAGSV3, MAINTAGV3Icons, MAINTAGV3Label } from '../../../types/mainTagV3'
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded'
import { FastFilter } from './FastFilters'

const LOGO_CLUB = 'LOGO_CLUB'

const FF_SX = { marginBottom: '4px' }

export const SecondLandingFastFiltersFrame = () => {
  const { filtersSelected, clubs, setNavButtonClicked } = useContext(AppContext)
  const fastFilters = []
  const allCategoriesSelected =
    filtersSelected[FilterOptionsEnum.MAINTAGV3].length === ALLMAINTAGSV3.length ||
    filtersSelected[FilterOptionsEnum.MAINTAGV3].length === 0
  const amountCategoriesSelected = filtersSelected[FilterOptionsEnum.MAINTAGV3].length

  const categoryLabel = allCategoriesSelected
    ? 'Todas las categorias'
    : amountCategoriesSelected === 1
    ? `${MAINTAGV3Label[filtersSelected[FilterOptionsEnum.MAINTAGV3][0]]}`
    : `${amountCategoriesSelected} Categorias`

  // filtro de "Restobar" (solo se muestra si no esta seleccionado)
  fastFilters.push(
    <FastFilter
      label={categoryLabel}
      icon={
        allCategoriesSelected
          ? null
          : MAINTAGV3Icons({})[filtersSelected[FilterOptionsEnum.MAINTAGV3][0]]
      }
      isActive={true}
      showExpand={true}
      sx={FF_SX}
      onClick={() => {
        setNavButtonClicked(FilterOptionsEnum.MAINTAGV3)
      }}
    />
  )

  const allRegionesSelected =
    filtersSelected[FilterOptionsEnum.UBICACION].length === 0 ||
    filtersSelected[FilterOptionsEnum.UBICACION].length === ALL_REGIONES.length
  const amountRegionesSelected = filtersSelected[FilterOptionsEnum.UBICACION].length
  const region = filtersSelected[FilterOptionsEnum.UBICACION][0]
  let label = region === RegionesEnum.METROPOLITANA ? 'Stgo.' : `${numberRegion[region]} Región`
  if (amountRegionesSelected > 1) {
    label += ` +${amountRegionesSelected - 1}`
  }
  fastFilters.push(
    <FastFilter
      label={allRegionesSelected ? 'Todo Chile' : label}
      icon={<LocationOnRoundedIcon fontSize="small" />}
      isActive={true}
      showExpand={true}
      sx={FF_SX}
      onClick={() => {
        setNavButtonClicked(FilterOptionsEnum.UBICACION)
      }}
    />
  )
  // filtro de dias activos (solo se muestra si esta activo el filtro)
  // const allDaysSelected =
  //   filtersSelected[FilterOptionsEnum.DIAS].length > 0 &&
  //   filtersSelected[FilterOptionsEnum.DIAS].length !== ALL_DAYS.length
  // fastFilters.push(
  //   <FastFilter
  //     label={
  //       allDaysSelected
  //         ? normalizeDaysToText(filtersSelected[FilterOptionsEnum.DIAS].join(','))
  //         : 'Todos los días'
  //     }
  //     icon={<TodayRoundedIcon fontSize="small" />}
  //     isActive={true}
  //     sx={FF_SX}
  //     onClick={() => {
  //       setNavButtonClicked(FilterOptionsEnum.DIAS)
  //     }}
  //     showExpand={true}
  //   />
  // )

  // filtro de tarjetas (siempre se muestra)
  const totalClubs = Object.keys(clubs || []).length
  const amountOfClubsSelected = filtersSelected[FilterOptionsEnum.TARJETAS].length || totalClubs
  const allClubsSelected = amountOfClubsSelected === totalClubs || amountOfClubsSelected === 0
  fastFilters.push(
    <FastFilter
      label={
        allClubsSelected
          ? 'Todas las tarjetas'
          : amountOfClubsSelected > 1
          ? ` ${amountOfClubsSelected} tarjetas`
          : ' 1 tarjeta'
      }
      icon={allClubsSelected ? <CreditCardRoundedIcon fontSize="small" /> : LOGO_CLUB}
      isActive={true}
      sx={FF_SX}
      onClick={() => {
        setNavButtonClicked(FilterOptionsEnum.TARJETAS)
      }}
      showExpand={true}
    />
  )

  // los margenes de top y bottom los metemos en los filterFrames, en caso de que no haya ninguno
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 10,
        flexWrap: 'wrap',
        paddingBottom: 10,
      }}
    >
      {fastFilters}
    </div>
  )
}
