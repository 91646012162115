import React, { useContext, useEffect, useState } from 'react'
import { SMBottomDrawer } from '../../../SMComponents/SMDrawer'
import { SMContent, SMFooter, SMVerticalDiv } from '../../../SMComponents/SMView'
import { SMH2, SMP } from '../../../SMComponents/SMText'
import { SMPrimaryButton } from '../../../SMComponents/SMButton'
import { SMTextField } from '../../../SMComponents/SMInput'
import { OpenReportErrorProps } from '../../../contexts/DiscountContext'
import { request } from '../../../helpers/request'
import { logSMEvent } from '../../../events/logEvent'
import { AppContext } from '../../../contexts/AppContext'

export const ReportErrorDrawer = ({
  open,
  setOpen,
  reportErrorDrawerProps,
}: {
  open: boolean
  setOpen: (newState: boolean) => void
  reportErrorDrawerProps: OpenReportErrorProps
}) => {
  const [message, setMessage] = useState('')
  const { handleOpenSnackBar, auth } = useContext(AppContext)

  useEffect(() => {
    if (open) {
      logSMEvent('OPEN_REPORT_ERROR_DRAWER', {
        source: reportErrorDrawerProps.source,
      })
    }
  }, [open])

  return (
    <SMBottomDrawer
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      sx={{ zIndex: 300 }}
    >
      <SMContent
        sx={{
          overflowY: 'auto', // to allow scrolling
        }}
      >
        <SMVerticalDiv>
          <SMH2>¿Encontraste un error con {`"${reportErrorDrawerProps.discount.titulo}"`}?</SMH2>
          <SMP>
            Tu ayuda es muy valiosa para nosotros. Al reportar un error, contribuyes a mejorar la
            calidad de nuestra información para ti y otros usuarios.
          </SMP>
        </SMVerticalDiv>
        <SMTextField
          validateOnBlur={!message}
          multiline
          rows={6}
          label="Por favor, describe el error que encontraste."
          value={message}
          onChange={(e: any) => setMessage(e.target.value)}
        />
      </SMContent>
      <SMFooter>
        <SMPrimaryButton
          fullWidth
          disabled={message === ''}
          onClick={() => {
            logSMEvent('SEND_REPORT_ERROR', {
              source: reportErrorDrawerProps.source,
            })
            request(
              'report_error',
              {
                method: 'POST',
                body: JSON.stringify({
                  discount: reportErrorDrawerProps.discount,
                  message,
                }),
              },
              auth
            )
            setOpen(false)
            setMessage('')
            handleOpenSnackBar({ message: '¡Muchas gracias! Reporte enviado con éxito.' })
          }}
        >
          Enviar
        </SMPrimaryButton>
      </SMFooter>
    </SMBottomDrawer>
  )
}
