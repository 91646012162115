export const primaryPrimary = '#2454F8' /* #3871e0; */
export const primary20Black = '#1D43C6'
export const primary40Black = '#163295'
export const primary60Black = '#0E2263'
export const primary80Black = '#071132'
export const primary20White = '#5076F9'
export const primary40White = '#7C98FB'
export const primary60White = '#A7BBFC'
export const primary80White = '#D3DDFE'
export const primary90White = '#E9EEFE'

export const secondarySecondary = '#727694'
export const secondary40White = '#AAADBF'
export const secondary40Black = '#444759'

export const neutralBlack = '#040819'
export const neutral50White = '#82848C'
export const neutral70White = '#B4B5BA'
export const neutral80White = '#DEE2E7'
export const neutral90White = '#E6E6E8'
export const neutralWinterWhite = '#F5F7F9'
export const neutralWhite = '#FFFFFF'

export const complementaryBackground = '#FFFFFF'
export const complementaryFeedback = '#20C8FE'

export const googleMapsStarColor = '#FFB32C'

export const textFieldStyle = {
  borderRadius: 18,
  backgroundColor: neutralWhite,
  minHeight: 50,
  maxWidth: 450,
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: primaryPrimary,
    borderRadius: 18,
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    borderRadius: 18,
  },
  '& .MuiInputLabel-root': {
    marginLeft: '10px', // adjust as needed
  },
  '& .MuiInputLabel-root.Mui-focused': {
    marginLeft: '10px',
    backgroundColor: 'white',
    padding: '0px 10px',
  },
}
