import React, { useEffect, useState } from 'react'
import { SMBottomDrawer } from '../../../SMComponents/SMDrawer'
import { SMContent, SMFooter, SMVerticalDiv } from '../../../SMComponents/SMView'
import { SMH2, SMP } from '../../../SMComponents/SMText'
import { SMPrimaryButton } from '../../../SMComponents/SMButton'
import { OpenReservationProps } from '../../../contexts/DiscountContext'
import { logSMEvent } from '../../../events/logEvent'
import NoResultsIlustration from '../../../assets/ilustrations/dinner.svg'
import { addUtmParamsToUrl } from '../../../helpers/others'

const style = {
  image: {
    width: '80%',
    maxWidth: '280px',
  },
}

export const ReservationDrawer = ({
  open,
  setOpen,
  reservationDrawerProps,
}: {
  open: boolean
  setOpen: (newState: boolean) => void
  reservationDrawerProps: OpenReservationProps
}) => {
  const [reservationLink, setReservationLink] = useState('')
  const isWhatsappLink = reservationDrawerProps.discount?.reservationLink?.includes('wa.me')

  useEffect(() => {
    if (open) {
      logSMEvent('OPEN_RESERVATION_DRAWER', {
        source: reservationDrawerProps.source,
        isWhatsappLink,
      })
    }
  }, [open])

  useEffect(() => {
    const getReservationLink = () => {
      const discount = reservationDrawerProps.discount
      const text = `Hola, me gustaría reservar una mesa en el local: ${discount.local}.`
      const encodedText = encodeURIComponent(text)
      if (reservationDrawerProps.discount?.reservationLink?.includes('wa.me')) {
        return `${reservationDrawerProps.discount.reservationLink}?text=${encodedText}`
      }
      return reservationDrawerProps.discount?.reservationLink || ''
    }
    setReservationLink(getReservationLink())
  }, [reservationDrawerProps.discount?.reservationLink])

  return (
    <SMBottomDrawer
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      sx={{ zIndex: 300 }}
    >
      <SMContent
        sx={{
          overflowY: 'auto', // to allow scrolling
        }}
      >
        <SMVerticalDiv>
          <SMH2>Reserva tu mesa en {`"${reservationDrawerProps.discount.local}"`}</SMH2>
          {isWhatsappLink ? (
            <SMP>
              Para reservar tu mesa, haz click en el botón a continuación para contactar a un
              ejecutivo vía WhatsApp.
            </SMP>
          ) : (
            <SMP>
              Para reservar tu mesa, haz clic en el botón a continuación y serás redirigido al sitio
              oficial de reservas del restaurante.
            </SMP>
          )}
          <SMP>
            En SaveMoney, nos esforzamos por brindarte la mejor experiencia posible. Por eso, te
            recomendamos reservar con anticipación y revisar las condiciones del descuento antes de
            tu visita.
          </SMP>
        </SMVerticalDiv>
        <SMVerticalDiv horizontalCenter verticalCenter sx={{ height: '100%' }}>
          <img src={NoResultsIlustration} style={style.image} alt="No results" />
        </SMVerticalDiv>
      </SMContent>
      <SMFooter>
        <SMPrimaryButton
          fullWidth
          href={addUtmParamsToUrl({ url: reservationLink })}
          onClick={() => {
            logSMEvent('RESERVATION_BUTTON_CLICKED_1', {
              hasUserJustLoggedIn: reservationDrawerProps.hasUserJustLoggedIn,
              source: reservationDrawerProps.source,
              discountId: reservationDrawerProps.discount.id,
              discountTitle: reservationDrawerProps.discount.titulo,
              discountLocal: reservationDrawerProps.discount.local,
              reservationLink: reservationDrawerProps.discount.reservationLink,
              isWhatsappLink,
            })
            setOpen(false)
          }}
        >
          {isWhatsappLink ? 'Contactar' : 'Redirigir al sitio'}
        </SMPrimaryButton>
      </SMFooter>
    </SMBottomDrawer>
  )
}
