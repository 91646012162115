import React from 'react'
import { Container, Button, Box, Link, Stack } from '@mui/material'
import { neutralWhite, primaryPrimary } from '../../../style'
import SaveMoneyLogo from '../../../assets/logo/logoTransparentBackground.png'
import Landing1 from '../../../assets/frames/landing1.png'
import { logSMEvent } from '../../../events/logEvent'
import MacLogo from '../../../assets/icons/macLogo.svg'
import Facebook from '../../../assets/icons/facebook.svg'
import Instagram from '../../../assets/icons/instagram.svg'
import Tiktok from '../../../assets/icons/tiktok.svg'
import GoogleLogo from '../../../assets/icons/googleLogo.svg'
import Whatsapp from '../../../assets/icons/whatsapp.svg'
import Email from '../../../assets/icons/email.svg'
import { appVersion } from '../../../consts/version'
import { useNavigate } from 'react-router-dom'
import './landing.css'
// xs is in pixels = to 600px

export const WebLanding = () => {
  const navigate = useNavigate()
  return (
    <Box sx={{ backgroundColor: primaryPrimary, minHeight: '100vh' }}>
      <Container
        maxWidth="xl" // 'lg' is equivalent to 1280px
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 12,
          paddingBottom: 4,
        }}
      >
        {/* Header */}
        <Stack
          direction="row"
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            pt: 1,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <img src={SaveMoneyLogo} alt="App Logo" style={{ width: 40, height: 40 }} />
            <h1 className="landing-header-font">SaveMoney</h1>
          </Box>
          <p className="landing-header-bywayo">by wayoalamos</p>
        </Stack>
        {/* Main Section */}
        <Container
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            gap: { xs: 0, md: 10 },
            margin: '-70px 0px',
            minWidth: '100%',
            // border: '1px solid red',
          }}
        >
          {/* Title and description */}
          <Box
            sx={{
              // border: '1px solid red',
              flex: 1,
              textAlign: { xs: 'center', md: 'left' },
              mb: { xs: 4, md: 0 },
            }}
          >
            <h1 className="landing-title">Aprovecha tus descuentos</h1>
            <h2 className="landing-description">
              La aplicación que reúne los descuentos de tus tarjetas bancarias, tus tarjetas
              vecinos, tu compañía de celular, tus billeteras digitales y más.
            </h2>
            <Button
              sx={{
                borderRadius: 40,
                backgroundColor: neutralWhite,
                textTransform: 'none',
                marginTop: 4,
                marginBottom: 4,
              }}
              onClick={(e: any) => {
                logSMEvent('LANDING_PRUEBA')
                e.stopPropagation() // prevent the click from propagating to the parent
                return navigate('/clubs')
              }}
              variant="contained"
            >
              <p
                className="landing-button-text"
                style={{
                  color: primaryPrimary,
                  margin: '4px 34px',
                  textWrap: 'nowrap',
                }}
              >
                Prueba la versión web
              </p>
            </Button>
            <p className="conditions-font">
              Al continuar aceptas nuestros{' '}
              <Link
                href="https://www.savemoney.cl/terminos.html"
                target="_blank"
                rel="noopener"
                style={{ color: neutralWhite, textDecoration: 'underline' }}
              >
                términos y condiciones
              </Link>
            </p>
          </Box>
          {/* Phone frames + download */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img
              src={Landing1}
              alt="App Screenshot"
              style={{ maxWidth: '90%', maxHeight: '80vh' }}
            />
            <Container
              sx={{
                display: 'flex',
                gap: 2,
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: 'center',
              }}
            >
              <Button
                onClick={() => {
                  window.open('https://apps.apple.com/us/app/savemoneycl/id6465695551', '_blank')
                  logSMEvent('CLICK_APP_STORE_CONTACT')
                }}
                sx={{
                  borderRadius: 40,
                  padding: '4px 28px',
                  backgroundColor: neutralWhite,
                  textTransform: 'none',
                  gap: 2,
                }}
              >
                <img src={MacLogo} alt="Mac" width={'28px'} />
                <p className="landing-button-text" style={{ color: primaryPrimary }}>
                  App Store
                </p>
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  window.open(
                    'https://play.google.com/store/apps/details?id=cl.savemoney.www.twa',
                    '_blank'
                  )
                  logSMEvent('CLICK_GOOGLE_STORE_CONTACT')
                }}
                sx={{
                  borderRadius: 40,
                  padding: '4px 28px',
                  borderColor: neutralWhite,
                  textTransform: 'none',
                  color: neutralWhite,
                  gap: 2,
                }}
              >
                <img src={GoogleLogo} alt="Goo" width={'28px'} />
                <p className="landing-button-text">Google Play</p>
              </Button>
            </Container>
          </Box>
        </Container>

        {/* Placeholder for other sections */}
        <Container>{/* Add your app explanation sections here */}</Container>

        {/* Join the comm */}
        <Box
          sx={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            width: '100%',
          }}
        >
          <p className="title-2">Únete a la comunidad</p>
          <Stack
            direction="row"
            justifyContent="space-around"
            sx={{ width: '100%', mt: 2, gap: 2 }}
          >
            {[
              { value: '+551K', label: 'Usuarios totales' },
              { value: '+120K', label: 'Visitas mensuales' },
              { value: '+17K', label: 'Nuevos usuarios mensuales' },
            ].map((item, index) => (
              <Box key={index} sx={{ flex: 1, textAlign: 'center', maxWidth: 150 }}>
                <p className="number-font">{item.value}</p>
                <p className="landing-description-2">{item.label}</p>
              </Box>
            ))}
          </Stack>
        </Box>

        {/* Follow Us Section */}
        <Box sx={{ textAlign: 'center', gap: 10, width: '100%' }}>
          <p className="title-2">Síguenos</p>
          <Stack direction="row" justifyContent="center" spacing={6} sx={{ width: '100%', mt: 4 }}>
            <Link
              href="https://www.tiktok.com/@savemoney.cl"
              target="_blank"
              rel="noopener"
              style={{ color: neutralWhite }}
            >
              <img src={Tiktok} alt="Tiktok" width={40} />
            </Link>
            <Link
              href="https://www.instagram.com/savemoney.cl/"
              target="_blank"
              rel="noopener"
              style={{ color: neutralWhite }}
            >
              <img src={Instagram} alt="Instagram" width={40} />
            </Link>
            <Link
              href="https://www.facebook.com/savemoneycl"
              target="_blank"
              rel="noopener"
              style={{ color: neutralWhite }}
            >
              <img src={Facebook} alt="Facebook" width={40} />
            </Link>
          </Stack>
        </Box>
        {/* Contact Us Section */}
        <Box
          sx={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            width: '100%',
          }}
        >
          <p className="title-2">Contáctanos</p>
          <Container
            sx={{
              display: 'flex',
              gap: 2,
              flexDirection: { xs: 'row', md: 'row' },
              justifyContent: 'center',
            }}
          >
            <Button
              onClick={() => {
                window.open('https://wa.me/56974769848', '_blank')
                logSMEvent('CLICK_WHATSAPP_CONTACT')
              }}
              sx={{
                borderRadius: 40,
                backgroundColor: neutralWhite,
                textTransform: 'none',
                maxWidth: 200,
                gap: 2,
                flex: 1,
              }}
            >
              <img src={Whatsapp} alt="Whatsapp" width={'20px'} />
              <p className="landing-button-text" style={{ color: primaryPrimary }}>
                Whatsapp
              </p>
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                window.location.href = 'mailto:wayoalamos@savemoney.cl'
                logSMEvent('CLICK_EMAIL_CONTACT')
              }}
              sx={{
                borderRadius: 40,
                borderColor: neutralWhite,
                textTransform: 'none',
                color: neutralWhite,
                maxWidth: 200,
                gap: 2,
                flex: 1,
              }}
            >
              <img src={Email} alt="Email" width={'20px'} />
              <p className="landing-button-text">Mail</p>
            </Button>
          </Container>
        </Box>
        {/* Version */}
        <Box
          sx={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <p className="conditions-font">v {appVersion}</p>
          <p className="conditions-font">By wayoalamos</p>
        </Box>
      </Container>
    </Box>
  )
}
