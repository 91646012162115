import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyANwvAiwHupOZHxb7wAfnUMIvKj3zVOMFs',
  authDomain: 'star-15bb5.firebaseapp.com',
  projectId: 'star-15bb5',
  storageBucket: 'star-15bb5.appspot.com',
  messagingSenderId: '560802384177',
  appId: '1:560802384177:web:30d8d676d2fc2e24850810',
  measurementId: 'G-6MNXNY3W9H',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const auth = getAuth(app)
