export const isiOS = !!navigator.userAgent.match(/iPhone|iPad|iPod/i)
export const isAndroid = /Android/i.test(navigator.userAgent)
export const isInSafari = /^((?!chrome|android|crios|fxios|opera|edg|msie|trident).)*safari/i.test(
  navigator.userAgent
)
export const isAppInstalled =
  window.matchMedia('(display-mode: standalone)').matches ||
  (window?.navigator as any)?.standalone ||
  document?.referrer?.includes('android-app://') ||
  (window.navigator.userAgent.includes('Samsung') &&
    window.matchMedia('(display-mode: minimal-ui)').matches)
export const isInstagram = navigator?.userAgent?.includes('Instagram')
export const isChrome =
  navigator.userAgent.includes('Chrome') && navigator.vendor.includes('Google Inc.')

export const isMobile = isiOS || isAndroid
