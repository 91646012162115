import React, { useContext } from 'react'

import ButtonTracked from '../../common/ButtonTracked'
import SwapVertRoundedIcon from '@mui/icons-material/SwapVertRounded'
import { AppContext } from '../../../contexts/AppContext'

export default function SortButton() {
  const { setNavButtonClicked } = useContext(AppContext)
  return (
    <ButtonTracked
      buttonname={`filter_Sort`}
      onClick={() => {
        return setNavButtonClicked('Sort')
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '3px',
        height: 28,
      }}
    >
      <SwapVertRoundedIcon fontSize="small" />
    </ButtonTracked>
  )
}
