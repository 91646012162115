import React from 'react'
import { Container, Button, Box, Link, Stack } from '@mui/material'
import { neutralWhite, primaryPrimary } from '../../../style'
import SaveMoneyLogo from '../../../assets/logo/logoTransparentBackground.png'
import { logSMEvent } from '../../../events/logEvent'
import Facebook from '../../../assets/icons/facebook.svg'
import Instagram from '../../../assets/icons/instagram.svg'
import Tiktok from '../../../assets/icons/tiktok.svg'
import WhatsappWhite from '../../../assets/icons/whatsappWhite.svg'
import Email from '../../../assets/icons/email.svg'
import { appVersion } from '../../../consts/version'
import { useNavigate } from 'react-router-dom'
import './landing.css'
import { SecondLandingFastFiltersFrame } from '../../discounts/components/SecondLandingFastFiltersFrame'
import { SMVerticalDiv } from '../../../SMComponents/SMView'
// xs is in pixels = to 600px

export const AppSecondLanding = () => {
  const navigate = useNavigate()
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 1,
        backgroundColor: primaryPrimary,
        height: '100vh',
      }}
    >
      {/* Header */}
      {/* <Stack
        direction="row"
        sx={{
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <h1 className="landing-header-font">SaveMoney</h1>
        </Box>
        <p className="landing-header-bywayo">by wayoalamos</p>
      </Stack> */}
      {/* Main Section */}
      <Container
        sx={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        {/* Title and description */}
        <SMVerticalDiv sx={{ gap: 0, marginTop: 20 }}>
          <h1 style={{ margin: 0, fontSize: 32, color: neutralWhite }}>SaveMoney</h1>
          <h1 style={{ marginTop: 0, fontSize: 20, color: neutralWhite, fontWeight: 'normal' }}>
            Gusto tenerte de vuelta
          </h1>
        </SMVerticalDiv>
        <SMVerticalDiv horizontalCenter sx={{ flexGrow: 1, justifyContent: 'space-around' }}>
          <img src={SaveMoneyLogo} alt="App Logo" style={{ width: 90, height: 90 }} />
          <SMVerticalDiv>
            <p className="subtitle-1" style={{ color: neutralWhite }}>
              Última vez utilizaste estos filtros:
            </p>
            <SecondLandingFastFiltersFrame />
          </SMVerticalDiv>
          <SMVerticalDiv>
            <Button
              sx={{
                borderRadius: 40,
                backgroundColor: neutralWhite,
                textTransform: 'none',
                width: '100%',
              }}
              onClick={(e: any) => {
                logSMEvent('SECOND_LANDING_VOLVER_A_FILTRAR')
                e.stopPropagation() // prevent the click from propagating to the parent
                return navigate('/days2')
              }}
              variant="contained"
            >
              <p
                className="landing-button-text"
                style={{
                  color: primaryPrimary,
                  margin: '2px ',
                  textWrap: 'nowrap',
                }}
              >
                Ver descuentos
              </p>
            </Button>
            <Button
              sx={{
                borderRadius: 40,
                border: '1px solid',
                textTransform: 'none',
                width: '100%',
                marginBottom: 2,
              }}
              onClick={(e: any) => {
                logSMEvent('LANDING_PRUEBA')
                e.stopPropagation() // prevent the click from propagating to the parent
                return navigate('/clubs')
              }}
              variant="contained"
            >
              <p
                className="landing-button-text"
                style={{
                  color: neutralWhite,
                  margin: '2px ',
                  textWrap: 'nowrap',
                }}
              >
                Volver a filtrar
              </p>
            </Button>
            <p className="conditions-font">
              Al continuar aceptas nuestros{' '}
              <Link
                href="https://www.savemoney.cl/terminos.html"
                target="_blank"
                rel="noopener"
                style={{ color: neutralWhite, textDecoration: 'underline' }}
              >
                términos y condiciones
              </Link>
            </p>
          </SMVerticalDiv>
        </SMVerticalDiv>
      </Container>
      {/* Social */}
      <Stack sx={{ width: '100%' }} spacing={3}>
        <Stack direction="row" sx={{ width: '100%' }}>
          {/* Follow Us Section */}
          <Box
            sx={{
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              flex: 1,
            }}
          >
            <p className="landing-description">Síguenos</p>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{ width: '100%' }}
            >
              <Link
                href="https://www.tiktok.com/@savemoney.cl"
                target="_blank"
                rel="noopener"
                style={{ color: neutralWhite }}
              >
                <img src={Tiktok} alt="Tiktok" width={20} />
              </Link>
              <Link
                href="https://www.instagram.com/savemoney.cl/"
                target="_blank"
                rel="noopener"
                style={{ color: neutralWhite }}
              >
                <img src={Instagram} alt="Instagram" width={20} />
              </Link>
              <Link
                href="https://www.facebook.com/savemoneycl"
                target="_blank"
                rel="noopener"
                style={{ color: neutralWhite }}
              >
                <img src={Facebook} alt="Facebook" width={20} />
              </Link>
            </Stack>
          </Box>
          {/* Contact Us Section */}
          <Box
            sx={{
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              flex: 1,
            }}
          >
            <p className="landing-description">Contáctanos</p>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{ width: '100%' }}
            >
              <Link
                href="mailto:wayoalamos@savemoney.cl"
                target="_blank"
                rel="noopener"
                style={{ color: neutralWhite }}
              >
                <img src={Email} alt="Email" width={'20px'} />
              </Link>
              <Link
                href="https://wa.me/56974769848"
                target="_blank"
                rel="noopener"
                style={{ color: neutralWhite }}
              >
                <img src={WhatsappWhite} alt="Whatsapp" width={'20px'} />
              </Link>
            </Stack>
          </Box>
        </Stack>

        {/* Version */}
        <Box
          sx={{
            textAlign: 'center',
          }}
        >
          <p className="conditions-font">v {appVersion}</p>
        </Box>
      </Stack>
    </Container>
  )
}
