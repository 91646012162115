import React, { useState } from 'react'
import { TextField } from '@mui/material'
import { MuiTelInput } from 'mui-tel-input'

export const SMTextField = ({ validateOnBlur, textOnBlurError, value, style, ...rest }: any) => {
  let showError = false
  let helperText = ''
  const [isBlur, setIsBlur] = useState(false)
  if (isBlur && validateOnBlur && textOnBlurError) {
    showError = !validateOnBlur(value)
    helperText = showError ? textOnBlurError : ''
  }
  return (
    <TextField
      error={showError}
      helperText={helperText}
      onBlur={() => setIsBlur(true)}
      onFocus={() => setIsBlur(false)}
      value={value}
      {...rest}
      style={{
        ...style,
      }}
    />
  )
}

export const SMMuiTelInput = ({ validateOnBlur, textOnBlurError, value, style, ...rest }: any) => {
  let showError = false
  let helperText = ''
  const [isBlur, setIsBlur] = useState(false)
  if (isBlur && validateOnBlur && textOnBlurError) {
    showError = !validateOnBlur(value)
    helperText = showError ? textOnBlurError : ''
  }
  return (
    <MuiTelInput
      error={showError}
      helperText={helperText}
      onBlur={() => setIsBlur(true)}
      onFocus={() => setIsBlur(false)}
      value={value}
      {...rest}
      style={{
        ...style,
      }}
    />
  )
}
